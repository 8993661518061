import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import "./DashPannelOne.css"; // Create a CSS file for custom styles

const DashPannelOne = ({ visibility = false }) => {
  if (!visibility) {
    return null;
  }

  function HomeComponent({ title, count, iconName, path }) {
    return (
      <div className="home-component">
        <Link to={path} className="statistic-link">
          <div className="statistic-box">
            <Icon name={iconName} size="large" />
            <div className="statistic-value">{count}</div>
            <div className="statistic-label">{title}</div>
          </div>
        </Link>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      <HomeComponent title="Institutes" count={"05"} iconName="university" path="/institutes" />
      <HomeComponent title="Faculties" count={"16"} iconName="graduation cap" path="/faculties" />
      <HomeComponent title="Departments" count={"192"} iconName="building" path="/departments" />
      <HomeComponent title="Programs" count={"552"} iconName="book" path="/programs" />
    </div>
  );
};

export default memo(DashPannelOne);
