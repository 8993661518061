import { useEffect } from "react";

export function useTitle(title) {
  useEffect(() => {
    // const prevTitle = document.title;
    document.title = title || "Namaste BHU"; // this will help in title undefined condition
    return () => {
      document.title = "Namaste BHU";
    };
  }, [title]);
}
