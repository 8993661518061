import React, { useState } from "react";
import Navbar from "../Navbar";
import SearchModal from "../Modals/SearchModal";
import { Container } from "semantic-ui-react";
import { useAuth } from "../../Context/AuthContext";
import "../../Css/NavbarWrapper.css"

export default function NavbarWrapper({ children, containerFluid=false, paddingLeft="5vw", paddingRight="5vw" }) {
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container className="navbar-wrapper-res" fluid={containerFluid} style={{ paddingLeft: paddingLeft, paddingRight: paddingRight }}>
        <div style={{ paddingTop: "90px" }}>{children}</div>
      </Container>
    </>
  );
}
