import React, { useState, useEffect } from "react";
import { Grid, Table } from "semantic-ui-react";
import { fetchSentSalaryComplaint } from "../api";
import LoaderComponent from "../LoaderComponent";
import NotificationStatsModal from "../Modals/NotificationStatsModal";
import NotificationSegment from "../Segments/NotificationSegment";
import SalaryMessageView from "./SalaryMessageView";
import SalaryMessageViewCard from "./SalaryMessageViewCard";

export function SalaryComplaintSent(props) {
  const [data, setData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
    // "#F7ECD7aa",
  ];

  useEffect(() => {
    if (props.complaint_salary_employee_id) {
      console.log("working");
      setLoading(true);
      fetchSentSalaryComplaint()
        .then((response) => response.json())
        .then((data) => {
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("Failed to fetch inbox list: ", error);
          setLoading(false);
        });
    } else {
      console.log("State/State.pcomplaint_salary_employee_id not available");
    }
  }, [props.complaint_salary_employee_id]);

  const selectMessage = (message) => {
    setSelectedMessage(message);
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <Grid>
      {isLoading ? (
        <LoaderComponent loadingText={"Loading Sent Notifications ..."}/>
      ) : (
        <>
          <Grid.Column
            width={9}
            style={{ maxHeight: "80vh", overflowY: "auto", minHeight:"80vh"}}
          >
            {isLoading ? (
              <LoaderComponent loadingText={"Loading Sent Notifications ..."} />
            ) : (
              <Table selectable striped>
                <Table.Body>
                  {data.length === 0 ? (
                    <Table.Row>
                      <Table.Cell textAlign="center" colSpan="3">
                        No complaints sent
                      </Table.Cell>
                    </Table.Row>
                  ) : (
                    data.map((item, index) => (
                      <SalaryMessageView
                        key={item.note_id}
                        item={item}
                        selectMessage={selectMessage}
                        cardBgColor={rowColors[index % rowColors.length]}
                        from="Inbox"
                      />
                    ))
                  )}
                </Table.Body>
              </Table>
            )}
          </Grid.Column>
          {selectedMessage ? (
            <Grid.Column
              width={7}
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <SalaryMessageViewCard
                id={selectedMessage} 
              />
            </Grid.Column>
          ) : (
            <Grid.Column
              width={7}
              style={{ maxHeight: "80vh", overflowY: "auto" }}
            >
              <NotificationSegment />
            </Grid.Column>
          )}
          <NotificationStatsModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            selectedMessage={selectedMessage}
          />
        </>
      )}
    </Grid>
  );
}
