import React, { useState, useEffect } from "react";
import { Table, Loader, Icon, Message, Placeholder } from "semantic-ui-react";
import { getOtInboxMessages, markNotificationAsRead } from "../api";
import { Link } from "react-router-dom";
import { formatDate } from "../utils";
import "./HomePageNotification.css";

const HomePageNotification = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const toURL = "/notifications";
  const toUrlState = (message) => ({ selectedMessage: message });

  const cardColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
  ];

  useEffect(() => {
    const personId = localStorage.getItem("person_id");
    const fetchMessages = async (id) => {
      setLoading(true);
      try {
        const response = await getOtInboxMessages(id);
        const data = await response.json();
        setMessages(data.slice(0, 7)); // Limit to 6 notifications
      } catch (error) {
        console.log("Failed to fetch inbox list: ", error);
      } finally {
        setLoading(false);
      }
    };

    if (personId) {
      fetchMessages(personId);
    } else {
      console.log("State/State.person_id not available");
    }
  }, []);

  const handleCardClick = async (message) => {
    const personId = localStorage.getItem("person_id");
    if (!message.is_read) {
      try {
        await markNotificationAsRead(personId, message.note_id);
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.note_id === message.note_id ? { ...msg, is_read: true } : msg
          )
        );
      } catch (error) {
        console.log("Failed to mark notification as read: ", error);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <Placeholder fluid>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : messages.length > 0 ? (
        <Table unstackable selectable>
          <Table.Body>
            {messages.map((message, index) => {
              const isRead = message.is_read;
              const textBoldClass =
                !isRead && "Inbox" ? "blackLink textBold" : "blackLink";
              return (
                <Table.Row
                  key={message.note_id}
                  onClick={() => handleCardClick(message)}
                  style={{
                    backgroundColor: cardColors[index % cardColors.length],
                    cursor: "pointer",
                  }}
                >
                  <Table.Cell
                    className="padding-control"
                    style={{maxWidth:"20px"}}
                  >
                    <Link to={toURL} state={toUrlState(message)}>
                      {isRead ? (
                        <Icon name="envelope open" color="grey" />
                      ) : (
                        <Icon name="envelope" color="blue" />
                      )}
                    </Link>
                  </Table.Cell>
                  <Table.Cell
                    className="overflow-control padding-control gname-size-control"
                  >
                    <Link
                      to={toURL}
                      state={toUrlState(message)}
                      className={textBoldClass}
                    >
                      {message.group.name}
                    </Link>
                  </Table.Cell>
                  {message.sender_name && (
                    <Table.Cell
                      className="overflow-control padding-control name-size-control"
                    >
                      <Link
                        to={toURL}
                        state={toUrlState(message)}
                        className={textBoldClass}
                      >
                        {message.sender_name}
                      </Link>
                    </Table.Cell>
                  )}
                  <Table.Cell
                    className="overflow-control padding-control title-size-control "
                  >
                    <Link
                      to={toURL}
                      state={toUrlState(message)}
                      className={textBoldClass}
                    >
                      {message.title}
                    </Link>
                  </Table.Cell>
                  <Table.Cell textAlign="right" className="padding-control">
                    <Link
                      to={toURL}
                      state={toUrlState(message)}
                      className={textBoldClass}
                    >
                      {formatDate(message.time_of_sending)}
                    </Link>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Message
          info
          header="No Notifications"
          content="There are no notifications available at this time."
        />
      )}
    </div>
  );
};

export default HomePageNotification;
