import React, { memo } from "react";
import { Grid, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import UpcomingHolidays from "./UpcomingHolidays";
import DashPannelOne from "./DashPannelOne";
import HomePageNotification from "./Notification/HomePageNotification";
import WeatherComponent from "./Weather/WeatherComponent";
import { useTitle } from "../Hooks/useTitle";
import BlinkingButton from "./AdmitCard/BlinkingButton";
import QuickNavPanel from "./QuickNavPanel";
import "../Css/Home.css"
import NavbarWrapper from "./Template/NavbarWrapper";

function Home() {
  useTitle("Namaste BHU")
  return (
    <>
    <NavbarWrapper containerFluid={true}>
          <BlinkingButton />
          <DashPannelOne visibility={true} />
          <QuickNavPanel />
          <Grid stackable columns={1} style={{ marginTop: "0" }}>
            <Grid.Column style={{marginTop:"1rem"}}>
              <Grid columns={2} centered stackable>
                <Grid.Column width={7}>
                  <WeatherComponent />
                </Grid.Column>
                <Grid.Column width={9} verticalAlign="middle">
                  <HomePageNotification />
                </Grid.Column>
              </Grid>
            </Grid.Column>

            <Grid.Column style={{marginTop:"-1rem"}}>
              {/* <h3
                style={{
                  marginBottom: "10px",
                  color: "black",
                  textAlign: "center",
                }}
              >
                Upcoming Holidays & Restricted Holidays
              </h3> */}
              <Segment
                basic
                as={Link}
                to="/holidays"
                style={{ width: "100%", textAlign: "center" }}
              >
                {/* <HolidayList /> */}
                {/* <HolidayDropdowns /> */}
                <UpcomingHolidays />
              </Segment>
            </Grid.Column>
          </Grid>
          </NavbarWrapper>
    </>
  );
}

export default memo(Home);
