import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Container,
  Header,
  Icon,
  Label,
  List,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import { useAuth } from "../../Context/AuthContext";
import Navbar from "../Navbar";
import SearchModal from "../Modals/SearchModal";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Import styles for the PDF viewer
import ImageUpload from "./UploadImages"; // Import the ImageUpload component
import DisplayData from "./DisplayData";
import { fetchCounseleeData } from "./api";
import ReactToPrint from "react-to-print";
import "../../Css/Print.css";
import { ScrollMode, SpecialZoomLevel } from "@react-pdf-viewer/core";
import DisplayIdentificationInformation from "./DisplayIdentificationInformation";

const CounseleeProfile = () => {
  const { counselee_id } = useParams();
  const [counselee, setCounselee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const [psy_admin_id, set_psy_admin_id] = useState();
  const [showAtt, setShowAtt] = useState(false);
  const printRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCounseleeData(counselee_id);
        setCounselee(data);
      } catch (error) {
        console.error("Error fetching counselee data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    set_psy_admin_id(localStorage.getItem("psy_admin_id"));
  }, [counselee_id]);

  if (loading) return <Loader active inline="centered" />;
  if (error) return <Message error header="Error" content={error} />;

  const handleViewDataClick = () => {
    setShowAtt(true);
  };

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
        <div
          className="no-padding"
          style={{ paddingTop: "90px", paddingBottom: "90px" }}
          ref={printRef}
        >
          {psy_admin_id && (
            <Segment color="green">
              {counselee.case_sheets[0]?.close && <Label ribbon color="red"> Closed </Label>}
              {/* <Card fluid> */}
              <Card.Content>
                <Card.Header>{counselee?.name || "Not Available "}</Card.Header>
                <Card.Meta>
                  Roll No: {counselee?.roll_no || "Not Available "}
                </Card.Meta>
                <Card.Description>
                  <List>
                    <List.Item>
                      <strong>Code:</strong>{" "}
                      {counselee?.code || "Not Available "}
                    </List.Item>
                    <List.Item>
                      <strong>Counsellor Name:</strong>{" "}
                      {counselee?.counsellor_name || "Not Available "}
                    </List.Item>
                    {counselee?.case_sheets?.map((caseSheet) => (
                      <List.Item key={caseSheet.id}>
                        <strong>Case Code:</strong> {caseSheet.case_code}
                      </List.Item>
                    ))}
                  </List>
                </Card.Description>
              </Card.Content>
              {/* </Card> */}
            </Segment>
          )}

          {psy_admin_id && (
            <>
              {/* <Segment className="no-display">
              <h3>Identification Information</h3> */}
              {counselee.case_sheets[0]?.info ? (
                <DisplayIdentificationInformation
                  jsonData={counselee.case_sheets[0].info}
                />
              ) : (
                <Segment>
                  <Header content="Identification Information" as={"h3"} />
                  <Container textAlign="center" style={{ paddingTop: "2rem" }}>
                    <Message warning>
                      <Message.Header>
                        Identification Information Not Available
                     </Message.Header>
                     <p>
                        Please ask the counsellor to upload the identification.
                     </p>
                      </Message>
                  </Container>
                </Segment>
              )}

              {/* {console.log(counselee.case_sheets[0].info)} */}
              {/* {counselee?.attachment ? (
                <>
                  {!showAtt && (
                    <Container textAlign="center" style={{ paddingTop: "2rem" }}>
                      <Button primary onClick={handleViewDataClick}>
                        <Icon name="eye" />
                        View Data
                      </Button>
                    </Container>
                  )}
                  {showAtt && (
  <div className="no-height-limit " style={{"height":"100%"}}>
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Viewer
        fileUrl={`https://namaste.bhu.edu.in${counselee.attachment}`}
        defaultScale={SpecialZoomLevel.PageFit} // Adjust to fit the PDF in the available space
        scrollMode={ScrollMode.Vertical} // Ensure all pages are stacked vertically
      />
    </Worker>
  </div>
)}
                  {/* {showAtt && (
                    <div className="no-height-limit " style={{ height: "750px" }}>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer
                          fileUrl={`https://namaste.bhu.edu.in${counselee.attachment}`}
                        />
                      </Worker>
                    </div>
                  )} */}
              {/* </Segment> */}
            </>
          )}

          <Segment>
            <Header content="Case Sheet" as={"h3"} style={{margin:"0"}}/>
            <DisplayData />
          </Segment>
        </div>

        {/* Print Button */}
        {/* Dissable Print Button, as it is not working properly */}
        {/* <ReactToPrint
          trigger={() => (
            <Button color="blue" floated="right">
              <Icon name="print" />
              Print
            </Button>
          )}
          content={() => printRef.current}
        /> */}
      </Container>
    </>
  );
};

export default CounseleeProfile;
