import { useState, useEffect } from "react";
import { fetchInstituteList } from "../../Components/api";
import LoaderComponent from "../../Components/LoaderComponent";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import Navbar from "../../Components/Navbar";
import SearchModal from "../../Components/Modals/SearchModal";
import DashPannelOne from "../DashPannelOne";
import NavbarWithoutLogin from "../WithoutLogin/NavbarWithoutLogin";
import { useTitle } from "../../Hooks/useTitle";

export default function InstituteList(props) {
  useTitle("Institutes")
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [institutesList, setList] = useState();
  const [isLoading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    setLoading(true);
    fetchInstituteList()
      .then((res) => res.json())
      .then((data) => {
        if (data && data.length > 0) {
          setList(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Failed to fetch institutes list: ", err);
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return <LoaderComponent loadingText="Loading institutes..." />;
  }
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <DashPannelOne visibility={true} />
          {/* <Divider /> */}
          <Segment basic padded>
            <Header as="h1">
              <Icon name="university" />
              <Header.Content>Institutes List</Header.Content>
            </Header>
            <Divider />
            {institutesList && institutesList.length > 0 ? (
              <Table celled basic compact striped textAlign="start">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Institute Name</Table.HeaderCell>
                    {/* <Table.HeaderCell>Parent(if exists)</Table.HeaderCell> */}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {institutesList.map((ins) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                          <Link
                            to={"/institute/" + ins.id + "/"}
                            state={{ instituteId: ins.id }}
                          >
                            {ins.name}
                          </Link>
                        </Table.Cell>
                        {/* <Table.Cell>{ins.parent ? ins.parent : "Not Available "}</Table.Cell> */}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            ) : (
              <h2> No data found</h2>
            )}
          </Segment>
        </div>
      </Container>
    </>
  );
}
