import { addAuthHeaders, SERVER_URL, postOptions } from "../api";

export async function checkPsyCounsellorRole() {
  const personId = localStorage.getItem("person_id");
  if (!personId) {
    console.error("person_id not found in local storage.");
    return;
  }
  const psyCounsellorUrl = `${SERVER_URL}/pwbs/api/person-2-counsellor/${personId}/`;
  const psyCounsellorAdminUrl = `${SERVER_URL}/pwbs/api/person-2-admin/${personId}/`;

  try {
    const headers = await addAuthHeaders();
    const psyCounsellorRoleResponse = await fetch(psyCounsellorUrl, {
      headers,
    });
    if (!psyCounsellorRoleResponse.ok) {
      throw new Error(
        `Failed to fetch counsellor_id: ${psyCounsellorRoleResponse.statusText}`
      );
    }
    const psyData = await psyCounsellorRoleResponse.json();
    const counsellorId = psyData.counsellor_id;
    localStorage.setItem("psy_counsellor_id", counsellorId);
  } catch (error) {
    console.log(error);
  }

  try {
    const headers = await addAuthHeaders();
    const adminResponse = await fetch(psyCounsellorAdminUrl, { headers });
    if (!adminResponse.ok) {
      throw new Error(`Failed to fetch admin_id: ${adminResponse.statusText}`);
    }
    const adminData = await adminResponse.json();
    const adminId = adminData.admin_id;
    localStorage.setItem("psy_admin_id", adminId);
  } catch (error) {
    console.log(error);
  }
}

export const getActiveCounsellors = async () => {
  const headers = await addAuthHeaders();
  return await fetch(`${SERVER_URL}/pwbs/api/counsellors/active/`, { headers });
};

export const getCounseleeList = async () => {
  const headers = await addAuthHeaders();
  return await fetch(`${SERVER_URL}/pwbs/api/counselees/`, { headers });
};

export const addCounselee = async (formBody) => {
  const headers = await addAuthHeaders();
  return await fetch(`${SERVER_URL}/pwbs/api/counselee/`, {
    method: "POST",
    headers,
    body: formBody,
  });
};

export const getCounseleeData = async (value) => {
  const headers = await addAuthHeaders();
  return await fetch(`${SERVER_URL}/pwbs/api/counselee/${value}/`, { headers });
};

export const updateCasesheet = async (caseSheetID, encryptedData) => {
  const headers = await addAuthHeaders();
  headers["Content-Type"] = "application/json";
  return await fetch(`${SERVER_URL}/pwbs/api/casesheet/${caseSheetID}/`, {
    method: "PATCH",
    headers,
    body: JSON.stringify({ body: encryptedData }),
  });
};

export const updateCounsellor = async (
  selectedCounselee,
  selectedCounsellor
) => {
  const headers = await addAuthHeaders();
  headers["Content-Type"] = "application/json";
  return await fetch(`${SERVER_URL}/pwbs/api/counselee/${selectedCounselee}/`, {
    method: "PATCH",
    headers,
    body: JSON.stringify({ counsellor: selectedCounsellor }),
  });
};

export const fetchCounseleeData = async (counselee_id) => {
  const headers = await addAuthHeaders();
  const response = await fetch(
    `${SERVER_URL}/pwbs/api/counselee/${counselee_id}/`,
    { headers }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchCounselees = async () => {
  const headers = await addAuthHeaders();
  const response = await fetch(`${SERVER_URL}/pwbs/api/counselees/`, {
    headers,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchCounseleesForCounsellor = async (psy_counsellor_id) => {
  const headers = await addAuthHeaders();
  const response = await fetch(
    `${SERVER_URL}/pwbs/api/counsellors/${psy_counsellor_id}/counselees/`,
    { headers }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchCounsellors = async () => {
  const headers = await addAuthHeaders();
  const response = await fetch(`${SERVER_URL}/pwbs/api/counsellors/`, {
    headers,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchExistingData = async (counselee_id) => {
  const headers = await addAuthHeaders();
  const response = await fetch(
    `${SERVER_URL}/pwbs/api/counselee/${counselee_id}/`,
    { headers }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchCaseSheet = async (caseSheetId) => {
  const headers = await addAuthHeaders();
  const response = await fetch(
    `${SERVER_URL}/pwbs/api/casesheet/${caseSheetId}/`,
    { headers }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const uploadFile = async (apiEndpoint, formData, caseSheetId) => {
  const headers = await addAuthHeaders();
  const requestOptions = {
    method: caseSheetId ? "PATCH" : "POST",
    headers,
    body: formData,
  };
  const response = await fetch(apiEndpoint, requestOptions);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const uploadCompressedImages = async (counselee_id, formData) => {
  const headers = await addAuthHeaders();
  const response = await fetch(
    `${SERVER_URL}/pwbs/api/counselee/${counselee_id}/`,
    {
      method: "PATCH",
      headers,
      body: formData,
    }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const fetchCaseSheetData = async (caseSheetId) => {
  const headers = await addAuthHeaders();
  const response = await fetch(
    `${SERVER_URL}/pwbs/api/casesheet/${caseSheetId}/`,
    { headers }
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const uploadCaseSheetData = async (
  apiEndpoint,
  formData,
  caseSheetId
) => {
  const headers = await addAuthHeaders();
  const requestOptions = {
    method: caseSheetId ? "PATCH" : "POST",
    headers,
    body: formData,
  };
  const response = await fetch(apiEndpoint, requestOptions);
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const pwbsFeedback = async (formData) => {
  const requestData = { ...postOptions };
  requestData.headers = {
    ...requestData.headers,
    ...(await addAuthHeaders()),
  };
  delete requestData.headers["Content-Type"];
  requestData.body = formData;
  const response = await fetch(
    `${SERVER_URL}/pwbs/api/send-feedback-email/`,
    requestData
  );

  if (response.ok) {
    alert("Feedback request sent successfully.");
  } else {
    alert("Failed to send Feedback Request \nPlease try again after sometime.");
    console.error(
      "Error: Feedback submission failed with status",
      response.status
    );
  }

  if (!response.ok) {
    throw new Error("Failed to submit the feedback.");
  }

  return response.json();
};

export const toggleCasesheet = async (close, counselee_id, caseSheetId) => {
  const formBodyFinal = new FormData();
  formBodyFinal.append("counselee", counselee_id);
  formBodyFinal.append("last_updated", new Date().toISOString().split("T")[0]);
  formBodyFinal.append("close", close);
  const apiEndpoint = caseSheetId
    ? `https://namaste.bhu.edu.in/pwbs/api/casesheet/${caseSheetId}/`
    : "https://namaste.bhu.edu.in/pwbs/api/casesheet/";

  try {
    const response = await uploadCaseSheetData(
      apiEndpoint,
      formBodyFinal,
      caseSheetId
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const userLoginLogs = async () => {
  const headers = await addAuthHeaders();
  return await fetch(`${SERVER_URL}/pwbs/api/admin/login-logs/`, { headers });
};
