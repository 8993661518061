import React, { useState, useEffect } from "react";
import { Card, Image, Icon, Placeholder, Message } from "semantic-ui-react";
import { fetchUserData } from "../api";
import Cookies from "js-cookie";

export default function ProfileCard({
  loading = false,
  person_id = "",
  userData = "",
  name = "",
  email = "",
  phone_number = "",
  profile_photo = null,
  person_t = "",
  designation = "",
  profile_link = "",
  insfaculty_name = "",
  program_name = "",
  department_name = "",
  dob = "",
  campus = "",
  entrance_rollno = "",
  exam_rollno = "",
  enrollment_no = "",
  degree = "",
  academic_year = "",
  in_current_academic_semester = "",
  year_of_admission = "",
  pass_out_year = "",
}) {
  const [profile, setProfile] = useState({
    name,
    email,
    phone_number,
    profile_photo,
    person_t,
    designation,
    profile_link,
    insfaculty_name,
    program_name,
    department_name,
    dob,
    campus,
    entrance_rollno,
    exam_rollno,
    enrollment_no,
    degree,
    academic_year,
    in_current_academic_semester,
    year_of_admission,
    pass_out_year,
  });

  const [loadingState, setLoadingState] = useState(loading);
  // console.log("userdata", userData);
  // const userData = JSON.parse(Cookies.get('user-data'));
  
  const [error, setError] = useState(false);
  const defaultImage = require("../../Resources/person.png");

  useEffect(() => {
    const fetchDataIfPersonId = async () => {
      if (person_id) {
        setLoadingState(true);
        setError(false); // Reset error before attempting fetch
        try {
          var result = "";
          if ( userData ) {
            result = userData;            
          } else {
            result = await fetchUserData(person_id)
          }          
          setProfile({
            name: result.details.person.name,
            email: result.details.person.email,
            phone_number: result.details.person.phone_number,
            profile_photo: result.details.person.profile_photo
              ? `https://namaste.bhu.edu.in${result.details.person.profile_photo}`
              : defaultImage,
            designation: result.details.person.designation,
            profile_link: result.details.profile_link,
            insfaculty_name: result.details.insfaculty_name,
            program_name: result.details.program_name,
            department_name: result.details.department_name || result.details.department,
            dob: result.details.dob,
            campus: result.details.campus,
            entrance_rollno: result.details.entrance_rollno,
            exam_rollno: result.details.exam_rollno,
            enrollment_no: result.details.enrollment_no,
            degree: result.details.degree,
            academic_year: result.details.academic_year,
            in_current_academic_semester:
              result.details.in_current_academic_semester,
            year_of_admission: result.details.year_of_admission,
            pass_out_year: result.details.pass_out_year,
          });
        } catch (error) {
          console.error("Failed to fetch user data:", error);
          setError(true); // Set error state if fetch fails
        } finally {
          setLoadingState(false);
        }
      }
    };

    fetchDataIfPersonId();
  }, [person_id]);

  const {
    name: profileName,
    email: profileEmail,
    phone_number: profilePhone,
    profile_photo: profilePhoto,
    designation: profileDesignation,
    profile_link: profileLink,
    insfaculty_name: profileInsFacultyName,
    program_name: profileProgramName,
    department_name: profileDepartmentName,
    dob: profileDOB,
    campus: profileCampus,
    entrance_rollno: profileEntranceRollNo,
    exam_rollno: profileExamRollNo,
    enrollment_no: profileEnrollmentNo,
    degree: profileDegree,
    academic_year: profileAcademicYear,
    in_current_academic_semester: profileCurrentSemester,
    year_of_admission: profileYearOfAdmission,
    pass_out_year: profilePassOutYear,
  } = profile;

  const hasExtraContent =
    profileInsFacultyName ||
    profileProgramName ||
    profileDepartmentName ||
    profileDOB ||
    profileCampus ||
    profileEntranceRollNo ||
    profileExamRollNo ||
    profileEnrollmentNo ||
    profileDegree ||
    profileAcademicYear ||
    profileCurrentSemester ||
    profileYearOfAdmission ||
    profilePassOutYear;

  const cardContent = (
    <Card.Content>
      {profileName && <Card.Header>{profileName}</Card.Header>}
      {profileDesignation && <Card.Meta>{profileDesignation}</Card.Meta>}
      {profileEmail && (
        <Card.Description>
          <Icon name="mail" /> {profileEmail}
        </Card.Description>
      )}
      {profilePhone && (
        <Card.Description>
          <Icon name="phone" /> {profilePhone}
        </Card.Description>
      )}
      {profileLink && (
        <Card.Description>
          <a href={profileLink} target="_blank" rel="noopener noreferrer">
            <Icon name="linkify" /> View Profile
          </a>
        </Card.Description>
      )}
    </Card.Content>
  );

  const extraContent = hasExtraContent && (
    <Card.Content extra>
      {profileInsFacultyName && <p>Faculty: {profileInsFacultyName}</p>}
      {profileProgramName && <p>Program: {profileProgramName}</p>}
      {profileDepartmentName && <p>Department: {profileDepartmentName}</p>}
      {profileDOB && <p>Date of Birth: {profileDOB}</p>}
      {profileCampus && <p>Campus: {profileCampus}</p>}
      {profileEntranceRollNo && (
        <p>Entrance Roll No: {profileEntranceRollNo}</p>
      )}
      {profileExamRollNo && <p>Exam Roll No: {profileExamRollNo}</p>}
      {profileEnrollmentNo && <p>Enrollment No: {profileEnrollmentNo}</p>}
      {profileDegree && <p>Degree: {profileDegree}</p>}
      {profileAcademicYear && <p>Academic Year: {profileAcademicYear}</p>}
      {profileCurrentSemester && (
        <p>Current Academic Semester: {profileCurrentSemester}</p>
      )}
      {profileYearOfAdmission && (
        <p>Year of Admission: {profileYearOfAdmission}</p>
      )}
      {profilePassOutYear && <p>Pass Out Year: {profilePassOutYear}</p>}
    </Card.Content>
  );

  return (
    <Card style={{ boxShadow: "none", padding: "1rem" }}>
      {loadingState ? (
        <>
          <Placeholder>
            <Placeholder.Image square />
          </Placeholder>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </>
      ) : error ? (
        <Message negative>
          <Message.Header>Error while fetching profile</Message.Header>
          <p>Please try again later.</p>
        </Message>
      ) : (
        <>
          {profilePhoto && (
            <img src={profilePhoto} alt="Profile" style={{ height: "250px" }} />
          )}
          {cardContent}
          {extraContent}
        </>
      )}
    </Card>
  );
}
