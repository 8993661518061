import React, { useState } from "react";
import { Header, Menu, Segment } from "semantic-ui-react";
import "../Css/Inbox.css";
import { HostelAdmin } from "../Components/SRK2/HostelAdmin";
import { HostelWarden } from "../Components/SRK2/HostelWarden";
import { useTitle } from "../Hooks/useTitle";
import NavbarWrapper from "../Components/Template/NavbarWrapper";

export default function Warden() {
  useTitle("Hostel Warden")
  const handleItemClick = (name) => setActiveItem(name);
  const hostel_admin_id = localStorage.getItem("hostel_admin_id");
  const hostel_warden_id = localStorage.getItem("hostel_warden_id");
  const [activeItem, setActiveItem] = useState(hostel_warden_id ? "sent" : "all-reports");

  return (
    <>
      <NavbarWrapper containerFluid={true}>
        <Header as={"h2"} style={{fontFamily: "Times New Roman, Georgia, serif"}}>Wardens</Header>
          <Menu
            pointing
            // style={srk_fellow_id ? { display: "flex" } : { display: "none" }}
          >
            {hostel_warden_id && (
              <Menu.Item
                name="sent"
                active={activeItem === "sent"}
                onClick={() => handleItemClick("sent")}
              />
            )}

            {hostel_admin_id && (
              <Menu.Item
                name="all-reports"
                active={activeItem === "all-reports"}
                onClick={() => handleItemClick("all-reports")}
              />
            )}
            {/* <Menu.Item
              name="create"
              active={activeItem === "create"}
              onClick={() => handleItemClick("create")}
            /> */}
          </Menu>

          <Segment>
            {activeItem === "sent" && (
              <HostelWarden srk_admin_id={hostel_warden_id} />
            )}
            {activeItem === "all-reports" && (
              <HostelAdmin srk_admin_id={hostel_admin_id} />
            )}

            {/* {authfac_id && activeItem === "sent" && <Sent authfac_id={authfac_id} />}  */}

            {/* {authfac_id && activeItem === "create" && <CreateForm />} */}
          </Segment>
          </NavbarWrapper>
          </>
)}
