import React, { useEffect, useState } from "react";
import {
  Container,
  Divider,
  Header,
  Message,
  Search,
  Select,
  Table,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import ScrollView from "../ScrollView";
import { fetchAttendanceData } from "./api";
import LoaderComponent from "../LoaderComponent";

const ShowAttendance = () => {
  const [data, setData] = useState([]);
  const currentMonth = new Date().getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    setLoadingState(true);
    const fetchData = async () => {
      try {
        const result = await fetchAttendanceData();        
        setData(result);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      } finally {
        setLoadingState(false);
      }
    };
    fetchData();    
  }, []);

  if (loadingState) {
    return <LoaderComponent loadingText={"Loading ..."} />;
  }

  const handleMonthChange = (e, { value }) => {
    setSelectedMonth(value);
  };

  const handleSearchChange = (e, { value }) => {
    setSearchTerm(value);
    if (value === "") {
      setSearchResults([]);
    } else {
      const filteredNames = allNames.filter((name) =>
        name.toLowerCase().includes(value.toLowerCase())
      );
      setSearchResults(filteredNames);
    }
  };

  const transformAttendanceData = (data) => {
    return data.map((record) => {
      const formattedPresent = record.present.map((aspirant) => ({
        id: aspirant.id,
        aspirant_name: aspirant.aspirant_name,
        roll_number: aspirant.roll_number
      }));

      const formattedAbsent = record.absent.map((aspirant) => ({
        id: aspirant.id,
        aspirant_name: aspirant.aspirant_name,
        roll_number: aspirant.roll_number
      }));

      return {
        date_of_attendance: record.date_of_attendance,
        present: formattedPresent,
        absent: formattedAbsent,
      };
    });
  };

  function fillAttendanceData(data, month) {
    const year = new Date().getFullYear(); // Assuming current year
    const daysInMonth = new Date(year, month, 0).getDate(); // Get number of days in the provided month

    // Create an empty attendance object for a given day
    const createEmptyAttendance = (day) => ({
      id: Math.floor(Math.random() * 1000), // Generate a random ID
      present: [],
      absent: [],
      date_of_attendance: String(day).padStart(2, "0"), // Format to show only the day
      marked_at: "",
    });

    // Convert provided data to a map based on day of the month for easy lookup
    const attendanceMap = data.reduce((acc, item) => {
      const day = new Date(item.date_of_attendance).getDate(); // Extract the day
      acc[day] = item;
      return acc;
    }, {});

    const filledData = [];

    // Loop through each day of the month and either fill in existing or empty attendance data
    for (let day = 1; day <= daysInMonth; day++) {
      if (attendanceMap[day]) {
        // If data exists for the day, format its `date_of_attendance` to show only the day
        attendanceMap[day].date_of_attendance = String(day).padStart(2, "0");
        filledData.push(attendanceMap[day]);
      } else {
        // Otherwise, create an empty attendance record
        filledData.push(createEmptyAttendance(day));
      }
    }

    return filledData;
  }

  function getAttendanceByMonth(data, month) {
    // Filter the data based on the month
    return data.filter((entry) => {
      const entryMonth = new Date(entry.date_of_attendance).getMonth() + 1; // +1 because getMonth() is zero-indexed
      return entryMonth === month;
    });
  }

  const data2 = fillAttendanceData(
    getAttendanceByMonth(data, selectedMonth),
    selectedMonth
  );

  const attendanceData = transformAttendanceData(data2);

  const isDataEmpty = (data) => {
    return data.every(
      (day) => day.present.length === 0 && day.absent.length === 0
    );
  };

  const allNames = [
    ...new Set(
      attendanceData.flatMap((day) => [
        ...day.present.map((p) => p.aspirant_name),
        ...day.absent.map((a) => a.aspirant_name),
      ])
    ),
  ].sort();
  console.log(allNames);
  

  const getStatus = (name, date) => {
    const dayData = attendanceData.find(
      (day) => day.date_of_attendance === date
    );
    if (dayData.present.some((p) => p.aspirant_name === name)) return "P";
    if (dayData.absent.some((a) => a.aspirant_name === name)) return "A";
    return "";
  };

  const getCellColor = (status) => {
    if (status === "P") return { backgroundColor: "#BBE9FF", color: "black" };
    if (status === "A") return { backgroundColor: "#FF8A8A", color: "black" };
    return {};
  };

  const monthOptions = [
    { key: 1, value: 1, text: "January" },
    { key: 2, value: 2, text: "February" },
    { key: 3, value: 3, text: "March" },
    { key: 4, value: 4, text: "April" },
    { key: 5, value: 5, text: "May" },
    { key: 6, value: 6, text: "June" },
    { key: 7, value: 7, text: "July" },
    { key: 8, value: 8, text: "August" },
    { key: 9, value: 9, text: "September" },
    { key: 10, value: 10, text: "October" },
    { key: 11, value: 11, text: "November" },
    { key: 12, value: 12, text: "December" },
  ];

  const filteredNames = searchTerm === "" ? allNames : searchResults;

  return (
    <>
      {/* <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ flex: "0 0 200px", marginRight: "1rem" }}>
          <Select
            placeholder="Select Month"
            options={monthOptions}
            value={selectedMonth}
            onChange={handleMonthChange}
            fluid // Make sure the select is full width within its container
          />
        </div>
        <div style={{ flex: "1" }}>
          <Search
            input={{ fluid: true }}
            placeholder="Search by name..."
            onSearchChange={handleSearchChange}
            value={searchTerm}
            showNoResults={false}
            fluid
          />
        </div>
      </div>
      <Divider /> */}
      {filteredNames.length === 0 ? (
        <Message warning>
          <Message.Header>No results found</Message.Header>
          <p>Please refresh the page</p>
          {/* <p>No aspirant found matching the search term "{searchTerm}".</p> */}
        </Message>
      ) : isDataEmpty(attendanceData) ? (
        <Message warning>
          <Message.Header>No data available</Message.Header>
          <p>No attendance data is available for the selected month.</p>
        </Message>
      ) : (
        <>
        <Header style={{padding:"0 0 0 0.75rem", marginBottom:"0.5rem", marginTop:"0"}}>October 2024 </Header>
          <ScrollView padding="0 0.75rem" maxHeight="70vh">
            <Table celled compact singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  {attendanceData.map((day) => (
                    <Table.HeaderCell
                      textAlign="center"
                      key={day.date_of_attendance}
                    >
                      {day.date_of_attendance}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {filteredNames.map((name) => (
                  <Table.Row key={name}>
                    <Table.Cell>{name}</Table.Cell>
                    {attendanceData.map((day) => {
                      const status = getStatus(name, day.date_of_attendance);
                      return (
                        <Table.Cell
                          textAlign="center"
                          key={day.date_of_attendance + name}
                          style={getCellColor(status)}
                        >
                          {status}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </ScrollView>
        </>
      )}
    </>
  );
};

export default ShowAttendance;