import React, { useState, useEffect } from "react";
import { Grid, Table, Message } from "semantic-ui-react";
import { getOtInboxMessages } from "../api";
import LoaderComponent from "../LoaderComponent";
import MessageViewCard from "../Cards/MessageViewCard";
import NotificationSegment from "../Segments/NotificationSegment";
import MessageTable from "../Tables/MessageTable";

export function Inbox(props) {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
    // "#F7ECD7aa",
  ];
  const inboxStyle = props.count
    ? {
        maxHeight: "80vh",
        overflowY: "auto",
      }
    : {
        minHeight: "80vh",
        maxHeight: "80vh",
        overflowY: "auto",
      };

  useEffect(() => {
    const personId = localStorage.getItem("person_id");
    const fetchMessages = (id) => {
      setLoading(true);
      getOtInboxMessages(id)
        .then((response) => response.json())
        .then((data) => {
          props.count ? setData(data.slice(0, 5)) : setData(data); // Limit to 5 notifications
          setLoading(false);
        })
        .catch((error) => {
          console.log("Failed to fetch inbox list: ", error);
          setLoading(false);
        });
    };

    if (props.person_id) {
      fetchMessages(props.person_id);
      if (props.selectedMessage) {
        setSelectedMessage(props.selectedMessage);
      }
    } else if (personId) {
      fetchMessages(personId);
    } else {
      console.log("State/State.person_id not available");
    }
  }, [props.person_id, props.selectedMessage, props.count]);

  const selectMessage = (message) => {
    setSelectedMessage(message);
  };

  return (
    <Grid stackable>
      {isLoading ? (
        <LoaderComponent loadingText={"Loading Notifications ..."} />
      ) : (
        <>
          <Grid.Column width={props.person_id ? 9 : 16} style={inboxStyle}>
            {data.length > 0 ? (
              <Table selectable striped>
                <Table.Body>
                  {data.map((item, index) => (
                    <MessageTable
                      key={item.note_id}
                      item={item}
                      selectMessage={selectMessage}
                      cardBgColor={rowColors[index % rowColors.length]}
                      from="Inbox"
                      person_id={props.person_id}
                    />
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <Message
                info
                header="No Notifications"
                content="There are no notifications available at this time."
              />
            )}
          </Grid.Column>
          {selectedMessage ? (
            <Grid.Column
              width={7}
              style={{
                transition: "opacity 0.5s",
                opacity: 1,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <MessageViewCard selectedMessage={selectedMessage} />
            </Grid.Column>
          ) : props.person_id ? (
            <Grid.Column
              width={7}
              style={{
                transition: "opacity 0.5s",
                opacity: 1,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <NotificationSegment />
            </Grid.Column>
          ) : null}
        </>
      )}
    </Grid>
  );
}
