import React from "react";
import { Container, Icon } from "semantic-ui-react";
import "./BlinkingButton.css";

const BlinkingButton = () => {
  const person_id = localStorage.getItem("person_id");
  const person_t = localStorage.getItem("person_t");

  if (person_t !== "STU") {
    return null;
  }
  if (!person_id) {
    alert("Something went wrong \nContact us at isystems@bhu.ac.in");
    return null;
  }

  const handleButtonClick = () => {
    window.open(
      `https://namaste.bhu.edu.in/people/api/admit_card/?p_id=${person_id}`,
      "_blank"
    );
  };

  return (
    <Container style={{ paddingBottom: "1rem" }} textAlign="center">
      <button
        className="blinking-button colorful-button"
        onClick={handleButtonClick}
      >
        <Icon name="user" />
        <span>2024-25 Students</span>
      </button>
    </Container>
  );
};

export default BlinkingButton;
