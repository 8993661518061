import React from "react";
import { Divider, Segment } from "semantic-ui-react";
import "./DisplayIdentificationInformation.css"; // Import the CSS file

const reasonLabels = {
  examAnxiety: "Exam Anxiety",
  academicDemotivation: "Academic Demotivation",
  acceptanceOfCourse: "Acceptance of Course",
  issuesWithLanguage: "Issues with Language of Studying",
  academicDisinterest: "Academic Disinterest",
  adjustmentIssues: "Adjustment Issues",
  relationshipIssues: "Relationship Issues",
  familyIssues: "Family Issues",
  confidenceIssues: "Confidence Issues",
  issuesWithEnglish: "Issues with Spoken English",
  financialIssues: "Financial Issues",
  disciplinaryIssues: "Disciplinary Issues",
  bullyingTeasing: "Bullying and Eve Teasing",
  ragging: "Ragging",
  selfHarm: "Self - Harm",
  abuse: "Abuse (Sexual / Physical/ Emotional)",
  substanceAbuse: "Substance Abuse",
  diversityInclusion: "Diversity & Inclusion",
  issuesWithFaculty: "Issues with Faculty members",
  issuesAtHostel: "Issues at Hostel",
  others: "Others",
};

const DisplayIdentificationInformation = ({ jsonData, showHeader = true }) => {
  if (!jsonData) {
    return null;
  }

  const data = JSON.parse(jsonData);

  const handleNewLines = (text) => (text ? text.replace(/\n/g, "<br />") : "-");

  return (
    <Segment color="green" className="identification-info">
      {showHeader && <h3>Identification Information</h3>}
      <Divider horizontal>Basic Information</Divider>
      <p>
        <strong>Date of Birth:</strong> {data.dateOfBirth || "-"}
      </p>
      <p>
        <strong>Age:</strong> {data.age || "-"}
      </p>
      <p>
        <strong>Religion and Caste:</strong> {data.religionAndCaste || "-"}
      </p>
      <p>
        <strong>Category:</strong> {data.category || "-"}
      </p>
      <p>
        <strong>Gender:</strong> {data.gender || "-"}
      </p>
      <p>
        <strong>Permanent Address:</strong>{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: handleNewLines(data.permanentAddress),
          }}
        />
      </p>
      <p>
        <strong>Local Address/Hostel Address:</strong>{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: handleNewLines(data.localAddress),
          }}
        />
      </p>
      <p>
        <strong>Mother Tongue and Other Spoken Languages:</strong>{" "}
        {data.languages || "-"}
      </p>
      <p>
        <strong>Class/Course:</strong> {data.classOrCourse || "-"}
      </p>
      <p>
        <strong>Institute/Faculty/College/School/Others:</strong>{" "}
        {data.institute || "-"}
      </p>
      <p>
        <strong>Marital Status:</strong> {data.maritalStatus || "-"}
      </p>
      <p>
        <strong>Spouse’s Name (if married):</strong> {data.spouseName || "-"}
      </p>

      <Divider horizontal>Parents' Information</Divider>
      <p>
        <strong>Father’s Name:</strong> {data.fatherName || "-"}
      </p>
      <p>
        <strong>Father’s Education:</strong> {data.fatherEducation || "-"}
      </p>
      <p>
        <strong>Father’s Occupation:</strong> {data.fatherOccupation || "-"}
      </p>
      <p>
        <strong>Mother’s Name:</strong> {data.motherName || "-"}
      </p>
      <p>
        <strong>Mother’s Education:</strong> {data.motherEducation || "-"}
      </p>
      <p>
        <strong>Mother’s Occupation:</strong> {data.motherOccupation || "-"}
      </p>
      <p>
        <strong>Personal Income/Family Income:</strong> {data.income || "-"}
      </p>

      {data.reasons && data.reasons.length > 0 && (
        <>
          <Divider horizontal>Additional Information</Divider>
          <p>
            <strong>Reasons to avail facility at PWBS:</strong>
          </p>
          <div className="reasons-list">
            {data.reasons.map((reasonKey, index) => (
              <div key={index}>
                {reasonLabels[reasonKey] || reasonKey}
                {data.reasonsDetails && data.reasonsDetails[index]
                  ? `: ${data.reasonsDetails[index]}`
                  : ""}
              </div>
            ))}
          </div>
        </>
      )}

      <Divider horizontal>Evaluation</Divider>
      <p>
        <strong>Evaluation of Self – Who Am I:</strong>{" "}
        <span
          dangerouslySetInnerHTML={{
            __html: handleNewLines(data.selfEvaluation),
          }}
        />
      </p>
      {/* <p><strong>Signature:</strong> {data.signature || "-"}</p> */}
    </Segment>
  );
};

export default DisplayIdentificationInformation;
