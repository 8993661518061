import React, { useState } from "react";
import {
  Button,
  Container,
  Header,
  Message,
  Modal,
  Segment,
} from "semantic-ui-react";
import DisplayIdentificationInformation from "./DisplayIdentificationInformation"; // Import your DisplayIdentificationInformation component

const IdentificationInformationModel = ({ info }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Identification Information</Button>

      <Modal open={open} onClose={() => setOpen(false)} size="large">
        <Modal.Header>Identification Information</Modal.Header>
        <Modal.Content>
          {info ? (
            <DisplayIdentificationInformation
              jsonData={info}
              showHeader={false}
            />
          ) : (
              <Container textAlign="center" style={{ paddingTop: "2rem" }}>
                <Message warning>
                  <Message.Header>
                    Identification Information Not Available
                  </Message.Header>
                  <p>Please upload the identification.</p>
                </Message>
              </Container>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default IdentificationInformationModel;
