import React, { useState } from "react";
import { Table, Header, Icon, Button, Label } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import NavbarWrapper from "../Template/NavbarWrapper";
import "./EmergencyContacts.css";
import { useTitle } from "../../Hooks/useTitle";

const emergencyContacts = [
  {
    category: "Chief Proctor Office, BHU",
    contacts: ["0542 2369 134", "0542 2369 242", "+91 88872 55334"],
  },
  {
    category: "Registrar",
    contacts: ["0542 2307 222", "0542 2368 558"],
  },
  {
    category: "Controller of Exams",
    contacts: ["0542 2307 255", "0542 2368 466"],
  },
  {
    category: "Dean of Students",
    contacts: ["0542 2369 043"],
  },
  {
    category: "B.H.U. Control Room",
    contacts: ["0542 2369 242"],
  },
  // {
  //   category: "Student Health Centre",
  //   contacts: ["0542 2307 610", "0542 2307 611"],
  // },
  {
    category: "Chairman, Anti-Ragging Squad",
    contacts: ["+91 80049 26062"],
  },
  {
    category: "S.S.H. Emergency Ward",
    contacts: ["0542 2309 308", "0542 2309 309"],
  },
  {
    category: "Ambulance BHU",
    contacts: ["0542 2369 088", "0542 2369 033"],
  },
  {
    category: "City",
    contacts: [],
  },
  {
    category: "Police Control Room",
    contacts: ["112"],
  },
  {
    category: "Lanka Police Station",
    contacts: ["0542 2366 656", "+91 94544 04390"],
  },
  {
    category: "S.P. City",
    contacts: ["+91 94544 01124", "0542 2414 141"],
  },
  {
    category: "Fire-Station, Bhelupura",
    contacts: ["0542 2277 666", "0542 2277 888", "+91 94159 08645"],
  },
  {
    category: "Ambulance",
    contacts: [
      "+91 87950 00042",
      "+91 87950 00043",
      "+91 89530 03632",
      "+91 89530 03633",
      "0542 2414 122",
      "0542 2368 888",
      "0542 2369 991",
      "0542 2369 995",
    ],
  },
];

const EmergencyContacts = () => {
  useTitle("Emergency Contacts")
  const [copiedText, setCopiedText] = useState(null); // State to track copied text

  // Function to handle copying the selected text
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedText(text);
      setTimeout(() => setCopiedText(null), 1000);
    });
  };

  return (
    <NavbarWrapper>
      <Header
        as="h2"
        textAlign="center"
        className="title-width-eme"
        style={{
          fontFamily: '"Times New Roman", Georgia, serif',
          margin: "0 auto",
        }}
      >
        <Icon name="phone" color="red" flipped="horizontally" /> List of
        Emergency Contacts
      </Header>
      <div
        style={{
          paddingTop: "2vh",
          paddingBottom: "5vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Table celled unstackable fixed compact style={{ maxWidth: "500px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Contact Numbers
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {emergencyContacts.map((contact, index) => {
              if (contact.category === "City") {
                return (
                  <Table.Row key={index}>
                    <Table.Cell colSpan="2" textAlign="center">
                      <Header
                        as="h3"
                        style={{
                          fontFamily: '"Times New Roman", Georgia, serif',
                        }}
                      >
                        City
                      </Header>
                    </Table.Cell>
                  </Table.Row>
                );
              }

              return (
                <Table.Row key={index}>
                  <Table.Cell>{contact.category}</Table.Cell>
                  <Table.Cell style={{ color: "#58a3f3" }}>
                    {contact.contacts.map((number, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          marginBottom: "10px",
                        }}
                      >
                        <strong>
                          <a
                            href={`tel:${number}`}
                            style={{
                              color: "#58a3f3",
                              textDecoration: "none",
                              marginRight: "10px",
                            }}
                          >
                            {number}
                          </a>
                        </strong>
                        <Button
                          size="mini"
                          icon="copy"
                          onClick={() => handleCopy(number)}
                          style={{ marginRight: "10px", padding: "0.25rem" }}
                        />
                        {copiedText === number && (
                          <Label style={{ padding: "0.25rem" }} color="green">
                            Copied!
                          </Label>
                        )}
                      </div>
                    ))}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </NavbarWrapper>
  );
};

export default EmergencyContacts;
