import React, { useState, useEffect} from "react";
import { Dropdown } from "semantic-ui-react";
import {
  programSearch,
  courseSearch,
  fetchInstituteList,
  fetchFacultiesList,
  fetchDepartmentList,
} from "../Components/api";
import { useNavigate } from "react-router-dom";

const CACHE_DURATION = process.env.REACT_APP_CACHE_DURATION;

const getCachedData = (key) => {
  const cached = localStorage.getItem(`c_${key}`);
  if (cached) {
    const { timestamp, data } = JSON.parse(cached);
    if (Date.now() - timestamp < parseInt(CACHE_DURATION)) {
      return data;
      
    }
  }
  return null;
};

const setCachedData = (key, data) => {
  localStorage.setItem(`c_${key}`, JSON.stringify({ timestamp: Date.now(), data }));
};

function NavbarSearchComponent() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [instituteList, setInstituteList] = useState([]);
  const [facultyList, setFacultyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    const cachedInstitutes = getCachedData("institutes");
    const cachedFaculties = getCachedData("faculties");
    const cachedDepartments = getCachedData("departments");

    if (cachedInstitutes) {
      setInstituteList(cachedInstitutes);
    } else {
      fetchInstituteList()
        .then((res) => res.json())
        .then((data) => {
          setInstituteList(data);
          setCachedData("institutes", data);
        })
        .catch((err) => console.error("Failed to fetch institutes list: ", err));
    }

    if (cachedFaculties) {
      setFacultyList(cachedFaculties);
    } else {
      fetchFacultiesList()
        .then((res) => res.json())
        .then((data) => {
          setFacultyList(data);
          setCachedData("faculties", data);
        })
        .catch((err) => console.error("Failed to fetch faculties list: ", err));
    }

    if (cachedDepartments) {
      setDepartmentList(cachedDepartments);
    } else {
      fetchDepartmentList()
        .then((res) => res.json())
        .then((data) => {
          setDepartmentList(data);
          setCachedData("departments", data);
        })
        .catch((err) => console.error("Failed to fetch department list: ", err));
    }
  }, []);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);

    if (value.length > 2) {
      setLoading(true);
      programSearch(`name=${value}`)
        .then((res) => res.json())
        .then((data) => {
          const programResults = data.map((item) => ({
            key: `program-${item.id}`,
            text: item.name,
            value: `program-${item.id}`,
            category: "Program",
          }));

          courseSearch(`name=${value}`)
            .then((res) => res.json())
            .then((courseData) => {
              const courseResults = courseData.map((item) => ({
                key: `course-${item.id}`,
                text: item.name,
                value: `course-${item.id}`,
                category: "Course",
              }));

              const instituteResults = instituteList
                .filter((institute) =>
                  institute.name.toLowerCase().includes(value.toLowerCase())
                )
                .map((item) => ({
                  key: `institute-${item.id}`,
                  text: item.name,
                  value: `institute-${item.id}`,
                  category: "Institute",
                }));

              const facultyResults = facultyList
                .filter((faculty) =>
                  faculty.name.toLowerCase().includes(value.toLowerCase())
                )
                .map((item) => ({
                  key: `faculty-${item.id}`,
                  text: item.name,
                  value: `faculty-${item.id}`,
                  category: "Faculty",
                }));

              const departmentResults = departmentList
                .filter((department) =>
                  department.name.toLowerCase().includes(value.toLowerCase())
                )
                .map((item) => ({
                  key: `department-${item.id}`,
                  text: item.name,
                  value: `department-${item.id}`,
                  category: "Department",
                }));

              setResults([
                ...programResults,
                ...courseResults,
                ...instituteResults,
                ...facultyResults,
                ...departmentResults,
              ]);
              setLoading(false);
            })
            .catch((err) => {
              console.error("Failed to search for courses: ", err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.error("Failed to search for programs: ", err);
          setLoading(false);
        });
    } else {
      setResults([]);
    }
  };

  const handleResultSelect = (e, { value }) => {
    const [type, id] = value.split("-");
    if (type === "program") {
      navigate(`/program/${id}/`);
    } else if (type === "course") {
      navigate(`/course/${id}/`);
    } else if (type === "institute") {
      navigate(`/institute/${id}/`);
    } else if (type === "faculty") {
      navigate(`/faculty/${id}/`);
    } else if (type === "department") {
      navigate(`/department/${id}/`);
    }
  };

  return (
    <Dropdown
      icon={"search"}
      search
      selection
      fluid
      placeholder="Search Institutes, Faculties, Departments, and Programs"
      style={{ width: "25vw" }}
      onSearchChange={handleSearchChange}
      options={results.map((result) => ({
        key: result.key,
        text: `${result.text} (${result.category})`,
        value: result.value,
      }))}
      onChange={handleResultSelect}
      loading={loading}
      noResultsMessage="No results found"
    />
  );
}

export default NavbarSearchComponent;