import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // Check for logged-in status in local storage on initial load
    const isUserLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    setLoggedIn(isUserLoggedIn);
  }, []);

  const login = () => {
    localStorage.setItem("isLoggedIn", "true");
    setLoggedIn(true);
  };

  const logout = () => {
    localStorage.clear();
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    setLoggedIn(false);
  };

  return (
    <AuthContext.Provider value={{ loggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use auth context
export const useAuth = () => useContext(AuthContext);
