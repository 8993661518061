import { useState, useEffect } from "react";
import { fetchFacultiesList } from "../Components/api";
import LoaderComponent from "../Components/LoaderComponent";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";

import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import DashPannelOne from "./DashPannelOne";
import NavbarWithoutLogin from "./WithoutLogin/NavbarWithoutLogin";
import { useTitle } from "../Hooks/useTitle";

export default function FacultyList(props) {
  useTitle("Faculties")
  const [facultyList, setList] = useState();
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [isLoading, setLoading] = useState(false);
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const dashPannelOneVisibility = props.dVisibility ? false : true;

  useEffect(() => {
    if (props.list && props.list.length > 0) {
      // means that the list is supplied by the parent invoking component

      // hence we can prevent making API call for getting complete department list

      setList(props.list);
    } else {
      setLoading(true);
      fetchFacultiesList()
        .then((res) => res.json())
        .then((data) => {
          if (data && data.length > 0) {
            setList(data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error("Failed to fetch faculties list: ", err);
          setLoading(false);
        });
    }
  }, [props.list]);

  if (isLoading) {
    return <LoaderComponent loadingText="Loading faculties list..." />;
  }
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <DashPannelOne visibility={dashPannelOneVisibility} />
          <Segment basic padded>
            <Header as="h1" className="heading-font">
              <Icon name="graduation cap" />
              <Header.Content>Faculty List</Header.Content>
            </Header>
            <Divider />
            {facultyList && facultyList.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Table
                  celled
                  basic
                  compact
                  striped
                  textAlign="start"
                  // collapsing
                  padded
                  size="large"
                  style={{ width: "66%" }}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Faculty Name</Table.HeaderCell>
                      {props.list ? null : (
                        <Table.HeaderCell>Parent</Table.HeaderCell>
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {facultyList.map((data) => {
                      return (
                        <Table.Row>
                          <Table.Cell width={6}>
                            <Link
                              to={"/faculty/" + data.id + "/"}
                              state={{ facultyid: data.id }}
                            >
                              {data.name}
                            </Link>
                          </Table.Cell>
                          {props.list ? null : (
                            <Table.Cell width={4}>
                              {data.parent ? data.parent : "_"}
                            </Table.Cell>
                          )}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </div>
            ) : null}
          </Segment>
        </div>
      </Container>
    </>
  );
}
