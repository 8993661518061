import Cookies from "js-cookie";

// A fn. to format the key of the incoming data obj for rendering purpose
export const formatResponseDataKey = (key) => {
  const textArr = key.split("_"); // Split the key on the basis of '_' character into words
  const formattedTextArr = textArr.map(
    (word) => word[0].toUpperCase() + word.substring(1)
  ); // Make the first letter capital of each word present in the key
  return formattedTextArr.join(" ");
};
export const capitalizeName = (fullName) => {
  if (fullName) {
    const fullNameArr = fullName.split(" "); // Split the name on the basis of ' ' character into words
    const formattedNameArr = fullNameArr.map(
      (word) => word[0] + word.substring(1).toLowerCase()
    ); // Make the first letter capital of each word present in the key
    return formattedNameArr.join(" ");
  } else {
    return "Not Available ";
  }
};
export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero indexed
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}
export function cropTitle(title, length = 40) {
  if (title && title.length > length) {
    return title.substring(0, length) + "...";
  }
  return title;
}

export function crop20(title, length = 20) {
  if (title && title.length > length) {
    return title.substring(0, length) + "...";
  }
  return title;
}

function joinArrayWithCommas(array) {
  return array.join(", ");
}

export function cropGp(gp, gps) {
  if (gps) {
    console.log("gps", gps);
    gp = joinArrayWithCommas(gp);
    if (gp && gp.length > 18) {
      return gp.substring(0, 18) + "...";
    }
  } else {
    if (gp && gp.length > 18) {
      return gp.substring(0, 18) + "...";
    }
  }
  return gp;
}

export function convertDateTimestamp(
  timestamp,
  timeOnly = false,
  timeFirst = false
) {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const ampm = date.getHours() < 12 ? "AM" : "PM";

  const time = `${hours}:${minutes} ${ampm}`;
  const fullDate = `${day}-${month}-${year}`;

  const separator = "\u00A0\u00A0\u00A0"; // Two non-breaking spaces

  return timeOnly
    ? time
    : timeFirst
    ? `${time},${separator}${fullDate}`
    : `${fullDate},${separator}${time}`;
}

export const linkDecorator = (href, text, key) => (
  <a
    href={href}
    body
    key={key}
    style={{ color: "blue !important" }}
    target="_blank"
    rel="noopener noreferrer"
  >
    {text}
  </a>
);

export var sortNames = (sent_to, selectedMessage) => {
  if (!selectedMessage || !selectedMessage.notification_status) {
    return [];
  }
  return sent_to.sort((a, b) => {
    const aDelivered = selectedMessage.notification_status.delivered_to.some(
      (del) => del.id === a.id
    );
    const bDelivered = selectedMessage.notification_status.delivered_to.some(
      (del) => del.id === b.id
    );
    const aRead = selectedMessage.notification_status.read_by.some(
      (read) => read.id === a.id
    );
    const bRead = selectedMessage.notification_status.read_by.some(
      (read) => read.id === b.id
    );
    if (aRead && !bRead) return -1;
    if (bRead && !aRead) return 1;
    if (aDelivered && !bDelivered) return -1;
    if (bDelivered && !aDelivered) return 1;
    return 0;
  });
};

export function isImageFile(filename) {
  const imageExtensions = ["jpg", "jpeg", "png"];
  const fileExtension = filename.split(".").pop().toLowerCase();
  return imageExtensions.includes(fileExtension);
}

const paksha = {
  "Shukla Paksha": "शुक्ल पक्ष",
  "Krishna Paksha": "कृष्ण पक्ष",
};

const tithi = {
  Pratipada: "प्रतिपदा",
  Dwitiya: "द्वितीया",
  Tritiya: "तृतीया",
  Chaturthi: "चतुर्थी",
  Panchami: "पंचमी",
  Shashthi: "षष्ठी",
  Saptami: "सप्तमी",
  Ashtami: "अष्टमी",
  Navami: "नवमी",
  Dashami: "दशमी",
  Ekadashi: "एकादशी",
  Dwadashi: "द्वादशी",
  Trayodashi: "त्रयोदशी",
  Chaturdashi: "चतुर्दशी",
  Amavasya: "अमावस्या",
  Purnima: "पूर्णिमा",
};

export function convertToHindi(term, type) {
  if (type === "paksha") {
    return paksha[term] || term;
  } else if (type === "tithi") {
    return tithi[term] || term;
  }
  return term;
}

export function getCurrentDateTime() {
  const now = new Date();

  let day = now.getDate();
  let month = now.getMonth() + 1; // Months are zero-based
  const year = now.getFullYear();

  let hours = now.getHours();
  const minutes = now.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Adding leading zeros
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;
  const strMinutes = minutes < 10 ? "0" + minutes : minutes;
  const strHours = hours < 10 ? "0" + hours : hours;

  return `${day}-${month}-${year} ${strHours}:${strMinutes} ${ampm}`;
}

export function getCurrentDate(dateFirst = false) {
  const now = new Date();

  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = now.getDate().toString().padStart(2, "0");
  if (dateFirst) {
    return `${day}-${month}-${year}`;
  }
  return `${year}-${month}-${day}`;
}

export function toSentenceCase(str) {
  if (!str) return str; // Return the string if it's empty or null
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}