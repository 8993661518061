import React, { useEffect, useState } from "react";
import { Header, Menu, Message, Segment } from "semantic-ui-react";
import AddCounselee from "../Components/Psy/AddCounselee";
import CounseleesTable from "../Components/Psy/CounseleesTable";
import ChangeCounsellor from "../Components/Psy/ChangeCounsellor";
import CounseleesList from "../Components/Psy/CounseleesList";
import CounsellorsTable from "../Components/Psy/CounsellorsTable";
import { getCounsellorStatus } from "../Components/api";
import LoaderComponent from "../Components/LoaderComponent";
import ShowUserLoginLogs from "../Components/Psy/ShowUserLoginLogs";
import NavbarWrapper from "../Components/Template/NavbarWrapper";
import { useTitle } from "../Hooks/useTitle";

export default function PsyCounseling() {
  useTitle("Psychological Counselling")
  const psy_counsellor_id = localStorage.getItem("psy_counsellor_id");
  const psy_admin_id = localStorage.getItem("psy_admin_id");
  const [activeItem, setActiveItem] = useState("counselees");
  const handleItemClick = (name) => setActiveItem(name);
  const [counsellorStatus, setCounsellorStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const returnCounsellorStatus = async () => {
      if (!psy_counsellor_id) {
        setCounsellorStatus(false);
        setIsLoading(false);
        return false;
      }
      const response = await getCounsellorStatus(psy_counsellor_id);
      if (response.is_active) {
        setCounsellorStatus(true);
        setIsLoading(false);
      } else {
        setCounsellorStatus(false);
        setIsLoading(false);
      }
    };

    returnCounsellorStatus();
  }, []);

  return (
    <>
      <NavbarWrapper>
        <Header
          as={"h2"}
          style={{ fontFamily: "Times New Roman, Georgia, serif" }}
        >
          Psychological Counselling 
        </Header>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <Menu
            pointing
            // style={psy_counsellor_id ? { display: "flex" } : { display: "none" }}
          >
            {psy_counsellor_id && counsellorStatus ? (
              <>
                <Menu.Item
                  name="counselees"
                  active={activeItem === "counselees"}
                  onClick={() => handleItemClick("counselees")}
                />
                <Menu.Item
                  name="add counselee"
                  active={activeItem === "add counselee"}
                  onClick={() => handleItemClick("add counselee")}
                />
              </>
            ) : psy_counsellor_id && !counsellorStatus ? (
              <Message negative style={{ width: "100%" }}>
                <Message.Header>
                  You are not authorized to access this page
                </Message.Header>
                <p>Please contact the administrator for further assistance.</p>
              </Message>
            ) : null}

            {psy_admin_id && (
              <>
                <Menu.Item
                  name="counselees"
                  active={activeItem === "counselees"}
                  onClick={() => handleItemClick("counselees")}
                />
                <Menu.Item
                  name="add counselee"
                  active={activeItem === "add counselee"}
                  onClick={() => handleItemClick("add counselee")}
                />
                <Menu.Item
                  name="all counsellor"
                  active={activeItem === "all counsellor"}
                  onClick={() => handleItemClick("all counsellor")}
                />
                <Menu.Item
                  name="change counsellor"
                  active={activeItem === "change counsellor"}
                  onClick={() => handleItemClick("change counsellor")}
                />
                <Menu.Item position="right">
                  <ShowUserLoginLogs />
                </Menu.Item>
              </>
            )}
          </Menu>
        )}
        {(counsellorStatus || psy_admin_id) && (
          <Segment>
            {activeItem === "add counselee" && (
              <AddCounselee setActiveItem={setActiveItem} />
            )}
            {activeItem === "counselees" && psy_admin_id ? (
              <CounseleesList />
            ) : (
              activeItem === "counselees" &&
              counsellorStatus && <CounseleesTable />
            )}
            {activeItem === "change counsellor" && <ChangeCounsellor />}
            {activeItem === "all counsellor" && <CounsellorsTable />}
          </Segment>
        )}
      </NavbarWrapper>
    </>
  );
}
