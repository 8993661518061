import React, { useState } from "react";
import NavbarWrapper from "../Components/Template/NavbarWrapper";
import { Header, Menu } from "semantic-ui-react";
import { isUserCSEOPAdmin } from "../Components/api";
import { useNavigate } from "react-router-dom";
import ShowAttendance from "../Components/Attendance/ShowAttendance";
import TakingAttendance from "../Components/Attendance/TakingAttendance";
import { useTitle } from "../Hooks/useTitle";

export default function CseopAttendance() {
  useTitle("CSEOP Attendance")
  const [activeItem, setActiveItem] = useState("View Attendance");
  const handleItemClick = (name) => setActiveItem(name);
  const navigate = useNavigate();
  !isUserCSEOPAdmin() && navigate("/");
  const headerStyle = { fontFamily: "Times New Roman, Georgia, serif" };

  return (
    <NavbarWrapper containerFluid={true}>
      <Header as={"h1"} style={headerStyle}>
       CSEOP Attendance
      </Header>
      <Menu pointing>
        <Menu.Item
          name="View Attendance"
          active={activeItem === "View Attendance"}
          onClick={() => handleItemClick("View Attendance")}
        />
        <Menu.Item
          name="Take Attendance"
          active={activeItem === "Take Attendance"}
          onClick={() => handleItemClick("Take Attendance")}
        />
      </Menu>

      {activeItem === "View Attendance" && (
          <ShowAttendance />
      )}
      {activeItem === "Take Attendance" && (
          <TakingAttendance />
      )}
    </NavbarWrapper>
  );
}
