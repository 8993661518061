import React, { useState, useEffect } from "react";
import {
  Card,
  Icon,
  Grid,
  Image,
  Loader,
  Dimmer,
  List,
} from "semantic-ui-react";
import "./WeatherComponent.css";
import { convertDateTimestamp, convertToHindi } from "../utils";
import { generateCToken } from "../utils-adv";
import morning from "../../Resources/morning.jpg"
import afternoon from "../../Resources/afternoon.jpg"
import evening from "../../Resources/evening.jpg"
import night from "../../Resources/night.jpg"

const WeatherComponent = () => {
  const [data, setData] = useState({
    totalData: null,
    weatherData: null,
    loading: true,
    error: false,
  });

  // State to store the current background image URL
  const [bgImage, setBgImage] = useState("");

  const bgColorForCard = "rgba(0,0,0, 0.5)";
  const returnEndTithi = () => "तिथ्यन्त्- ";

  // Function to determine the background image based on time
  const getBackgroundImage = () => {
    const currentHour = new Date().getHours();
    if (currentHour >= 5 && currentHour < 12) {
      return morning; 
    } else if (currentHour >= 12 && currentHour < 17) {
      return afternoon;
    } else if (currentHour >= 17 && currentHour < 20) {
      return evening;
    } else {
      return night;
    }
  };

  const getWeatherData = async () => {
    try {
      const personId = localStorage.getItem("person_id");
      const response = await fetch("https://namaste.bhu.edu.in/weather/api/current/", {
        headers: {
          Authorization: process.env.REACT_APP_AUTH_TOKEN,
          ctoken: await generateCToken(personId, process.env.REACT_APP_CTOKEN_KEY),
        },
      });
      const data = await response.json();
      setData({
        totalData: data,
        weatherData: data.weather.body.current,
        loading: false,
        error: false,
      });
    } catch (error) {
      console.error("Error fetching weather data:", error);
      setData({
        ...data,
        loading: false,
        error: true,
      });
    }
  };

  useEffect(() => {
    getWeatherData();
    
    // Set the background image based on time when component mounts
    setBgImage(getBackgroundImage());
  }, []);

  if (data.loading) {
    return (
      <Dimmer active inverted>
        <Loader>Loading</Loader>
      </Dimmer>
    );
  }

  if (data.error || !data.weatherData) {
    return <div>Error fetching weather data</div>;
  }

  const { totalData, weatherData } = data;

  return (
    <div
      className="weather-container"
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover", // Ensures the image covers the entire container
        backgroundPosition: "center", // Centers the background image
        backgroundRepeat: "no-repeat", // Prevents tiling of the image
        width: "100%", // Makes sure the container stretches across the entire width
      }}
    >
      <Card fluid style={{ backgroundColor: bgColorForCard, padding: "1.5rem" }}>
        <Grid columns={2}>
          <Grid.Column width={7}>
            <Card.Content>
              <Card.Header style={{ fontSize: "2.5rem" }}>
                Varanasi
                <Image
                  src={`https:${weatherData.condition.icon}`}
                  size="tiny"
                  style={{ marginLeft: "10px" }}
                />
              </Card.Header>
              <Card.Meta style={{ fontSize: "1.5rem" }}>
                {weatherData.temp_c}°
              </Card.Meta>
              <Card.Description>
                <p>Feels Like {weatherData.feelslike_c}°</p>
                <p>{weatherData.condition.text}</p>
              </Card.Description>
            </Card.Content>
          </Grid.Column>
          <Grid.Column textAlign="right" verticalAlign="bottom" width={9}>
            {totalData.panchang.body.data.tithi.map((tithi, index) => (
              <List.Content key={index}>
                <List.Description>
                  <strong>
                    {convertToHindi(tithi.name, "tithi")} (
                    {convertToHindi(tithi.paksha, "paksha")})
                  </strong>
                  <br />
                  {returnEndTithi()}
                  {new Date(tithi.end).toLocaleString()}
                </List.Description>
              </List.Content>
            ))}
            <p>
              Humidity <Icon name="tint" />
              {weatherData.humidity}%
            </p>
            <p>
              Wind <Icon name="compass" />
              {weatherData.wind_dir} {weatherData.wind_kph} km/h
            </p>
            <p>Sunrise - Sunset</p>
            <p>
              {convertDateTimestamp(totalData.panchang.body.data.sunrise, true)}
              -
              {convertDateTimestamp(totalData.panchang.body.data.sunset, true)}
            </p>
          </Grid.Column>
        </Grid>
      </Card>
    </div>
  );
};

export default WeatherComponent;
