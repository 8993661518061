import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  Icon,
  Input,
  Button,
  Container,
  Pagination,
} from "semantic-ui-react";
import {
  fetchSrkAdminReports,
  createCommentSRK,
  returnSrkAdminMetaData,
} from "../api";
import LoaderComponent from "../LoaderComponent";
import NotificationSegment from "../Segments/NotificationSegment";
import SrkMessageTable from "../Tables/SrkMessageTable";
import SrkMessageViewCard from "../Cards/SrkMessageViewCard";
import SrkFellowAnalysisCard from "../Cards/SrkFellowAnalysisCard";
import "../../Css/SrkInbox.css";
import SrkGodModeAnalysisModal from "../Modals/SrkGodModeAnalysisModal";
import CommentModal from "../CommentModal"; // Import the CommentModal component

export function SrkInbox(props) {
  const [analysisData, setAnalysisData] = useState();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedMsgUsername, setSetelectedMsgUsername] = useState();
  const [sortColumn, setSortColumn] = useState("time_of_sending");
  const [sortDirection, setSortDirection] = useState("descending");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [commentSubmitted, setCommentSubmitted] = useState(false);

  const rowColors = [
    "#D1CCE6aa",
    "#F7D1C5aa",
    "#B7DFF9aa",
    "#F7D8DCaa",
    "#E8B5B8aa",
    "#EEDDF4aa",
    "#B8CDEBaa",
    "#F9C3A6aa",
    "#D4D4D4aa",
  ];

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const openCommentModal = () => setCommentModalOpen(true);
  const closeCommentModal = () => setCommentModalOpen(false);

  useEffect(() => {
    async function fetchData() {
      if (props.srk_admin_id) {
        fetchPageData(currentPage);
      } else {
        console.log("State/State.person_id not available");
      }
    }
    fetchData()
  }, [props.srk_admin_id, currentPage]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await returnSrkAdminMetaData(props.srk_admin_id);
        const result = await response.json();
        setAnalysisData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [props.srk_admin_id]);

  const fetchPageData = (page) => {
    setLoading(true);
    fetchSrkAdminReports(props.srk_admin_id, page)
      .then((response) => response.json())
      .then((data) => {
        setData(
          data.results.sort(
            (a, b) => new Date(b.time_of_sending) - new Date(a.time_of_sending)
          )
        );
        setTotalPages(Math.ceil(data.count / 100));
        setLoading(false);
      })
      .catch((error) => {
        console.log("Failed to fetch inbox list: ", error);
        setLoading(false);
      });
  };

  const selectMessage = (message) => {
    setSelectedMessage(message);
  };

  const toggleAnalysisView = () => {
    setShowAnalysis(!showAnalysis);
  };

  const setAnalysisView = (value) => {
    setShowAnalysis(value);
  };

  const handleSort = (clickedColumn) => () => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn);
      setSortDirection("ascending");
      setData(data.slice().sort((a, b) => a[clickedColumn] - b[clickedColumn]));
    } else {
      setSortDirection(
        sortDirection === "ascending" ? "descending" : "ascending"
      );
      setData(data.slice().reverse());
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCommentSubmit = (comment) => {
    const commentator = localStorage.getItem("srk_admin_id");
    const payload = {
      report: selectedMessage,
      comment: comment,
      commentator: commentator,
    };
    createCommentSRK(payload)
      .then(() => {
        closeCommentModal();
        setCommentSubmitted(!commentSubmitted); // Toggle the commentSubmitted state to trigger a re-render
        // Add any additional actions here, like refreshing the data or showing a success message
      })
      .catch((error) => {
        console.log("Failed to submit comment: ", error);
      });
  };

  const filteredData = data.filter((item) =>
    item.sender_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage);
  };

  // const returnSRKAdminReportsMetaData = async (srk_admin_id) => {
  //   return await fetch(`https://namaste.bhu.edu.in/srk/api/admin-reports-meta/${srk_admin_id}/`)
  // }

  return (
    <Grid>
      {isLoading ? (
        <LoaderComponent loadingText={"Loading Notifications ..."} />
      ) : (
        <>
          <SrkGodModeAnalysisModal
            srk_admin_id={props.srk_admin_id}
            data={analysisData}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
          <Grid.Column
            width={9}
            style={{ maxHeight: "80vh", overflowY: "auto", minHeight: "80vh" }}
          >
            <Grid columns={2} stackable>
              <Grid.Column width={15}>
                <Input
                  fluid
                  icon="search"
                  placeholder="Search by Sender's Name..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Grid.Column>
              <Grid.Column
                width={1}
                style={{ paddingLeft: "0" }}
                textAlign="right"
              >
                <Button icon="line graph" color="blue" onClick={openModal} />
              </Grid.Column>
            </Grid>
            <Table selectable striped color="blue">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={
                      sortColumn === "time_of_sending" ? sortDirection : null
                    }
                    onClick={handleSort("time_of_sending")}
                    style={{ cursor: "pointer" }}
                  >
                    Submission Date
                    {sortColumn === "time_of_sending" && (
                      <Icon
                        name={
                          sortDirection === "ascending"
                            ? "sort up"
                            : "sort down"
                        }
                      />
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>Sender's Name</Table.HeaderCell>
                  <Table.HeaderCell>Content</Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sortColumn === "report_date" ? sortDirection : null}
                    onClick={handleSort("report_date")}
                    textAlign="right"
                    style={{ cursor: "pointer" }}
                  >
                    Report's Date
                    {sortColumn === "report_date" && (
                      <Icon
                        name={
                          sortDirection === "ascending"
                            ? "sort up"
                            : "sort down"
                        }
                      />
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {filteredData.map((item, index) => (
                  <SrkMessageTable
                    key={item.id}
                    item={item}
                    selectMessage={selectMessage}
                    setSetelectedMsgUsername={setSetelectedMsgUsername}
                    cardBgColor={rowColors[index % rowColors.length]}
                    from="srk"
                    setAnalysisView={setAnalysisView}
                  />
                ))}
              </Table.Body>
            </Table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "0",
              }}
            >
              <Pagination
                activePage={currentPage}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                // boundaryRange={1}
                // siblingRange={1}
                // ellipsisItem={null}
              />
            </div>
          </Grid.Column>
          {selectedMessage ? (
            <Grid.Column
              width={7}
              style={{
                transition: "opacity 0.5s",
                opacity: 1,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <Container fluid textAlign="right">
                <Button icon onClick={toggleAnalysisView}>
                  <Icon name="calendar alternate" />
                </Button>
                <Button icon onClick={openCommentModal} color="blue">
                  <Icon name="add" />
                  Add Comments
                </Button>
              </Container>

              {showAnalysis && (
                <SrkFellowAnalysisCard data={data} name={selectedMsgUsername} />
              )}

              <SrkMessageViewCard
                id={selectedMessage}
                commentSubmitted={commentSubmitted}
              />
            </Grid.Column>
          ) : (
            <Grid.Column
              width={7}
              style={{
                transition: "opacity 0.5s",
                opacity: 1,
                maxHeight: "80vh",
                overflowY: "auto",
              }}
            >
              <NotificationSegment />
            </Grid.Column>
          )}
        </>
      )}
      <CommentModal
        open={isCommentModalOpen}
        onClose={closeCommentModal}
        onSubmit={handleCommentSubmit}
      />
    </Grid>
  );
}
