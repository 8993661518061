import React, { useState, useEffect, memo } from "react";
import {
  Form,
  Button,
  Message,
  Modal,
  Input,
  Icon,
  Checkbox,
  Container,
  TextArea,
  Header,
  Segment,
  Grid,
  Dropdown,
  // Grid,
} from "semantic-ui-react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import imageCompression from "browser-image-compression";
import { PDFDocument } from "pdf-lib";
import CryptoJS from "crypto-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./PsyForm.css"; // Make sure to create and import the CSS file
import passwordCheckingModel from "./passwordCheckingModel";
import Navbar from "../Navbar";
import SearchModal from "../Modals/SearchModal";
import { useAuth } from "../../Context/AuthContext";
// import FileUpload from "./FileUpload";
import ScrollButton from "./ScrollButton";
import { getCurrentDateTime } from "../utils";
import {
  fetchCounseleeData,
  fetchCaseSheetData,
  uploadCaseSheetData,
} from "./api"; // Import the functions from api.js
import Feedback from "./Feedback";
import IdentificationForm from "./IdentificationForm";
import DisplayIdentificationInformation from "./DisplayIdentificationInformation";
import ToggleCasesheetBtn from "./ToggleCasesheetBtn";
import IdentificationInformationModel from "./IdentificationInformationModel";
const pStyle = { fontSize: "0.8rem" };
const segmentColor = "yellow";
const segmentStyle = { backgroundColor: "#F7ECD7aa" };

const SecureForm = () => {
  const { counselee_id } = useParams();
  const [sessionNumber, setSessionNumber] = useState(1);
  const initialFormData = {
    caseCode: "",
    studentCode: "",
    name: "",
    email: "",
    dateOfBirth: "",
    age: "",
    gender: "",
    institute: "",
    contactNoEmail: "",
    emergencyContact: "",
    majorConcern: "",
    severity: "",
    presentingSymptoms: [{ symptom: "", duration: "" }],
    dsmVClassification: "",
    personalityTraits: "",
    bodyStatus: "",
    thinkingTraits: "",
    emotionalTraits: "",
    biologicalFeatures: "",
    medicalHistory: "",
    psychiatricHistory: "",
    familyPsychiatricHistory: "",
    counselingHistory: "",
    presentMedicalStatus: "",
    biological: "",
    psychiatric: "",
    anyMedicinesBeingPresentlyTaken: "",
    overallSocialStatus: "",
    groupPeerMembership: "",
    familyRelationships: "",
    supportSystem: "",
    maritalStatus: "",
    extraCurricular: "",
    hobbies: "",
    strengths: "",
    testAdministered: [{ test: "", scores: "", interpretations: "" }],
    education: [
      { level: "", marks: "", selfSatisfaction: "", parentSatisfaction: "" },
    ],
    selfExpectations: "",
    parentalExpectations: "",
    schoolCollegeEvaluation: "",
    casePlan: "",
    sessionDate: getCurrentDateTime(),
    sessionNote: "",
    sessionProblem: "",
    sessionInterventions: "",
    sessionChange: "",
    sessionNotes: [
      {
        date: getCurrentDateTime(),
        details: "",
        problem: "",
        interventions: "",
        changes: "",
      },
    ],
    closureNotes: "",
    counselorName: "",
    terminationNotes: "",
    counselorSignature: "",
    extra: "",
    lastSession: 1,
  };

  // setInterval(() => {
  //   setFormData({...formData, sessionDate:getCurrentDateTime()})
  // }, 1000);

  const [formData, setFormData] = useState(initialFormData);
  const [existingData, setExistingData] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState(false);
  const [encryptedData, setEncryptedData] = useState(null);
  const [caseSheetId, setCaseSheetId] = useState(null);
  const [caseSheetCloseStatus, setCaseSheetCloseStatus] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  // const [apiData, setApiData] = useState({});
  const [key, setKey] = useState(0);
  const { logout } = useAuth();
  const [isModalOpen, setModalOpen2] = useState(false);
  const closeModal = () => setModalOpen2(false);
  const [idInfo, setIdInfo] = useState(null);
  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1); // Navigate one page back
  };
  const counsellor_id = localStorage.getItem("psy_counsellor_id");
  const { state } = useLocation();
  // console.log(state.counselee.id);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCounseleeData(counselee_id);
        if (data.case_sheets[0] && data.case_sheets[0].body !== "null") {
          setIdInfo(data.case_sheets[0].info);
          // console.log(data.case_sheets[0].body);
          setFormData({
            ...formData,
            name: data.name || "",
            email: data.email || "",
            caseCode: data.case_sheets[0].case_code || "",
            studentCode: data.code || "",
          });
          setEncryptedData(data.case_sheets[0].body);
          setCaseSheetId(data.case_sheets[0].id);
          setCaseSheetCloseStatus(data.case_sheets[0].close);
          setUserEmail(data.email);
          // console.log(data);

          // setSessionNumber(data.case_sheets[0].lastSession);
        } else {
          data.case_sheets[0] && setCaseSheetId(data.case_sheets[0].id);
          setEncryptedData(null);
        }
        if (encryptedData) {
          setPasswordModalOpen(true);
        }
        setFormData({
          ...formData,
          caseCode:
            (data.case_sheets[0] && data.case_sheets[0].case_code) || "",
          name: state.counselee.name ? state.counselee.name : data.name,
          studentCode: state.counselee.code,
          email: data.email,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [counselee_id, encryptedData, key]);

  // setFormData({...formData,
  //   sessionNotes: { date: getCurrentDate(), details: "", problem: "", interventions: "", changes: "" }
  // })

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSessionChange = (e, { value }) => {
    setSessionNumber(value);
    // You can add additional logic here, such as updating parent state or handling the selected value
  };

  const handleArrayChange = (index, field, value, arrayName) => {
    const updatedArray = [...formData[arrayName]];
    updatedArray[index][field] = value;
    setFormData({ ...formData, [arrayName]: updatedArray });
  };

  const addArrayItem = (arrayName, newItem) => {
    setFormData({
      ...formData,
      [arrayName]: [...formData[arrayName], newItem],
    });
  };

  const removeArrayItem = (arrayName, index) => {
    const updatedArray = [...formData[arrayName]];
    updatedArray.splice(index, 1);
    setFormData({ ...formData, [arrayName]: updatedArray });
  };

  const handleSave = () => {
    if (!sessionNumber) {
      alert("Session is not selected \nPlease select the counselling session");
      return null;
    }
    setFormData({ ...formData, lastSession: sessionNumber });
    setModalOpen(true);
  };

  const encryptData = (data, password) => {
    return CryptoJS.AES.encrypt(data, password).toString();
  };

  const decryptData = (encryptedData, password) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, password);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.log(error); // error
      return null;
    }
  };

  const handleModalSave = async () => {
    setIsCheckboxChecked(false);
    let pdfBlob = null;
    if (file) {
      pdfBlob = await handleFileUpload();
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
    let savedData = {};

    if (encryptedData) {
      const decryptedData = decryptData(encryptedData, password);
      if (decryptedData) {
        savedData = decryptedData;
      } else {
        alert("Wrong Password");
        // console.log("Wrong Password");
        return;
      }
    }

    const mergeArrays = (arr1, arr2) => {
      if (arr1.length > 0 && arr2.length > 0) {
        return [...arr1, ...arr2].filter((item) => {
          return Object.values(item).some((value) => value !== "");
        });
      } else if (arr1.length > 0) {
        return arr1.filter((item) => {
          return Object.values(item).some((value) => value !== "");
        });
      } else if (arr2.length > 0) {
        return arr2.filter((item) => {
          return Object.values(item).some((value) => value !== "");
        });
      } else {
        return null;
      }
    };

    const mergeFields = (savedField, newField, addDate = false) => {
      if (savedField && newField) {
        if (addDate) {
          return `${savedField} \n -------------------------------------------- \n Session ${sessionNumber}, (${getCurrentDateTime()}): \n -------------------------------------------- \n ${newField}`;
        } else {
          return savedField + "\n" + newField;
        }
      } else if (savedField) {
        return savedField;
      } else if (newField) {
        if (addDate) {
          return `Session ${sessionNumber}, (${getCurrentDateTime()}): \n -------------------------------------------- \n ${newField}`;
        } else {
          return newField;
        }
      } else {
        return savedField;
      }
    };

    const newFormData = {
      ...savedData,
      // caseCode: mergeFields(savedData.caseCode, formData.caseCode),
      // studentCode: mergeFields(savedData.studentCode, formData.studentCode),
      // name: mergeFields(savedData.name, formData.name),
      dateOfBirth: mergeFields(savedData.dateOfBirth, formData.dateOfBirth),
      age: mergeFields(savedData.age, formData.age),
      gender: mergeFields(savedData.gender, formData.gender),
      institute: mergeFields(savedData.institute, formData.institute, true),
      contactNoEmail: mergeFields(
        savedData.contactNoEmail,
        formData.contactNoEmail,
        true
      ),
      emergencyContact: mergeFields(
        savedData.emergencyContact,
        formData.emergencyContact,
        true
      ),
      majorConcern: mergeFields(
        savedData.majorConcern,
        formData.majorConcern,
        true
      ),
      severity: formData.severity || savedData.severity,
      presentingSymptoms: mergeArrays(
        savedData.presentingSymptoms || [],
        formData.presentingSymptoms || []
      ),
      dsmVClassification: mergeFields(
        savedData.dsmVClassification,
        formData.dsmVClassification,
        true
      ),
      personalityTraits: mergeFields(
        savedData.personalityTraits,
        formData.personalityTraits,
        true
      ),
      bodyStatus: mergeFields(savedData.bodyStatus, formData.bodyStatus, true),
      thinkingTraits: mergeFields(
        savedData.thinkingTraits,
        formData.thinkingTraits,
        true
      ),
      emotionalTraits: mergeFields(
        savedData.emotionalTraits,
        formData.emotionalTraits,
        true
      ),
      biologicalFeatures: mergeFields(
        savedData.biologicalFeatures,
        formData.biologicalFeatures,
        true
      ),
      medicalHistory: mergeFields(
        savedData.medicalHistory,
        formData.medicalHistory,
        true
      ),
      psychiatricHistory: mergeFields(
        savedData.psychiatricHistory,
        formData.psychiatricHistory,
        true
      ),
      familyPsychiatricHistory: mergeFields(
        savedData.familyPsychiatricHistory,
        formData.familyPsychiatricHistory,
        true
      ),
      counselingHistory: mergeFields(
        savedData.counselingHistory,
        formData.counselingHistory,
        true
      ),
      presentMedicalStatus: mergeFields(
        savedData.presentMedicalStatus,
        formData.presentMedicalStatus,
        true
      ),
      biological: mergeFields(savedData.biological, formData.biological, true),
      psychiatric: mergeFields(
        savedData.psychiatric,
        formData.psychiatric,
        true
      ),
      anyMedicinesBeingPresentlyTaken: mergeFields(
        savedData.anyMedicinesBeingPresentlyTaken,
        formData.anyMedicinesBeingPresentlyTaken,
        true
      ),
      overallSocialStatus: mergeFields(
        savedData.overallSocialStatus,
        formData.overallSocialStatus,
        true
      ),
      groupPeerMembership: mergeFields(
        savedData.groupPeerMembership,
        formData.groupPeerMembership,
        true
      ),
      familyRelationships: mergeFields(
        savedData.familyRelationships,
        formData.familyRelationships,
        true
      ),
      supportSystem: mergeFields(
        savedData.supportSystem,
        formData.supportSystem,
        true
      ),
      maritalStatus: mergeFields(
        savedData.maritalStatus,
        formData.maritalStatus,
        true
      ),
      extraCurricular: mergeFields(
        savedData.extraCurricular,
        formData.extraCurricular,
        true
      ),
      hobbies: mergeFields(savedData.hobbies, formData.hobbies, true),
      strengths: mergeFields(savedData.strengths, formData.strengths, true),
      testAdministered: mergeArrays(
        savedData.testAdministered || [],
        formData.testAdministered || []
      ),
      education: mergeArrays(
        savedData.education || [],
        formData.education || []
      ),
      selfExpectations: mergeFields(
        savedData.selfExpectations,
        formData.selfExpectations,
        true
      ),
      parentalExpectations: mergeFields(
        savedData.parentalExpectations,
        formData.parentalExpectations,
        true
      ),
      schoolCollegeEvaluation: mergeFields(
        savedData.schoolCollegeEvaluation,
        formData.schoolCollegeEvaluation,
        true
      ),
      casePlan: mergeFields(savedData.casePlan, formData.casePlan, true),
      sessionDate: mergeFields(
        savedData.sessionDate,
        `Session ${sessionNumber}, ${getCurrentDateTime()}`
      ),
      sessionNote: mergeFields(
        savedData.sessionNote,
        formData.sessionNote,
        true
      ),
      sessionProblem: mergeFields(
        savedData.sessionProblem,
        formData.sessionProblem,
        true
      ),
      sessionInterventions: mergeFields(
        savedData.sessionInterventions,
        formData.sessionInterventions,
        true
      ),
      sessionChange: mergeFields(
        savedData.sessionChange,
        formData.sessionChange,
        true
      ),
      // sessionNotes: mergeArrays(
      //   savedData.sessionNotes || [],
      //   formData.sessionNotes || []
      // ),
      closureNotes: mergeFields(
        savedData.closureNotes,
        formData.closureNotes,
        true
      ),
      counselorName: mergeFields(
        savedData.counselorName,
        formData.counselorName,
        true
      ),
      terminationNotes: mergeFields(
        savedData.terminationNotes,
        formData.terminationNotes,
        true
      ),
      counselorSignature: mergeFields(
        savedData.counselorSignature,
        formData.counselorSignature,
        true
      ),
      extra: mergeFields(savedData.extra, formData.extra, true),
      lastSession: sessionNumber,
    };

    const encryptedNewData = encryptData(JSON.stringify(newFormData), password);

    localStorage.setItem("formData", encryptedNewData);

    const formBodyFinal = new FormData();
    formBodyFinal.append("body", encryptedNewData);
    if (file) {
      formBodyFinal.append("attachment", pdfBlob, "attachment.pdf");
    }
    formBodyFinal.append("counselee", counselee_id);
    formBodyFinal.append(
      "last_updated",
      new Date().toISOString().split("T")[0]
    );
    const apiEndpoint = caseSheetId
      ? `https://namaste.bhu.edu.in/pwbs/api/casesheet/${caseSheetId}/`
      : "https://namaste.bhu.edu.in/pwbs/api/casesheet/";

    try {
      const data = await uploadCaseSheetData(
        apiEndpoint,
        formBodyFinal,
        caseSheetId
      );
      setFile(null);
      setExistingData(newFormData);
      setFormData(initialFormData); // Clear the form fields after saving
      setTimeout(() => setModalOpen(false), 1500);
      setSuccessMessage(true);
      setTimeout(() => setSuccessMessage(false), 3000);
      if (!caseSheetId) {
        setCaseSheetId(data.id); // Assuming the response contains the new case sheet ID
      }
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => setKey(key + 1), 1500);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
    // const formBodyFinal = new FormData();
    // formBodyFinal.append("body", encryptedNewData);
    // if (file) {
    //   formBodyFinal.append("attachment", pdfBlob, "attachment.pdf");
    // }
    // formBodyFinal.append("counselee", counselee_id);
    // formBodyFinal.append(
    //   "last_updated",
    //   new Date().toISOString().split("T")[0]
    // );

    // const apiEndpoint = caseSheetId
    //   ? `https://namaste.bhu.edu.in/pwbs/api/casesheet/${caseSheetId}/`
    //   : "https://namaste.bhu.edu.in/pwbs/api/casesheet/";

    // const requestOptions = {
    //   method: caseSheetId ? "PATCH" : "POST",
    //   headers: {
    //     Authorization: "Token 7e10b5afcfe50486b8c4a90075514dfbbb169fa7",
    //   },
    //   body: formBodyFinal,
    // };

    // fetch(apiEndpoint, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setFile(null);
    //     setExistingData(newFormData);
    //     setFormData(initialFormData); // Clear the form fields after saving
    //     setTimeout(() => setModalOpen(false), 1500);
    //     // setModalOpen(false);
    //     setSuccessMessage(true);
    //     setTimeout(() => setSuccessMessage(false), 3000);
    //     if (!caseSheetId) {
    //       setCaseSheetId(data.id); // Assuming the response contains the new case sheet ID
    //     }
    //     // setPasswordModalOpen(true);
    //     setPassword("");
    //     setConfirmPassword("");
    //     setTimeout(() => setKey(key + 1), 1500);
    //     window.location.reload();
    //   })
    //   .catch((error) => console.error("Error:", error));
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    // const encryptedData = localStorage.getItem("formData");
    const decryptedData = decryptData(encryptedData, password);
    if (decryptedData) {
      setExistingData(decryptedData);
      setPasswordModalOpen(false);
      setPassword("");
      setSessionNumber(decryptedData["lastSession"]);
    } else {
      setPasswordErrorMessage(true);
      setTimeout(() => setPasswordErrorMessage(false), 3000);
    }
  };

  const renderExistingData = (label, field, type = "text", extra = null) => {
    let isDisable = false;
    if (
      field === "caseCode" ||
      field === "studentCode" ||
      field === "name" ||
      field === "sessionDate" ||
      field === "email"
    ) {
      isDisable = true;
    }
    const formattedText =
      existingData &&
      existingData[field] &&
      existingData[field].split("\n").map((item, key) => {
        return (
          <span key={key}>
            {item}
            <br />
          </span>
        );
      });
    return (
      <Form.Field>
        <label>{label}</label>
        {extra && <p style={pStyle}>{extra}</p>}
        {existingData && existingData[field] && (
          <Message className="message-box">
            {/* <Message.Header>Existing Data:</Message.Header> */}
            <p>{formattedText}</p>
          </Message>
        )}
        {type === "text" ? (
          <Form.Input
            name={field}
            value={formData[field]}
            onChange={handleChange}
            readOnly={isDisable}
            input={{
              style: isDisable
                ? {
                    backgroundColor: "#E8E8E8",
                    color: "#808080",
                    cursor: "not-allowed",
                  }
                : {},
            }}
          />
        ) : (
          <TextArea
            name={field}
            value={formData[field]}
            onChange={handleChange}
          />
        )}
      </Form.Field>
    );
  };

  const renderArrayData = (label, arrayName, fields) => {
    return (
      <div>
        <Header as="h4">{label}</Header>
        {existingData &&
          existingData[arrayName] &&
          existingData[arrayName].map((item, index) => (
            <Message key={index} className="message-box">
              {/* <Message.Header>Existing Data:</Message.Header> */}
              {fields.map((field) => (
                <p key={field.name}>
                  {field.label}: {item[field.name]}
                </p>
              ))}
            </Message>
          ))}
        {formData[arrayName].map((item, index) => (
          <Form.Group key={index} widths="16">
            {fields.map((field) => (
              <Form.Field width="16" key={field.name}>
                <label>{field.label}</label>
                <Form.Input
                  value={item[field.name]}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      field.name,
                      e.target.value,
                      arrayName
                    )
                  }
                />
              </Form.Field>
            ))}
            <Form.Field>
              <label>{"Remove"}</label>
              <Button
                icon="trash"
                color="red"
                onClick={() => removeArrayItem(arrayName, index)}
              />
            </Form.Field>
          </Form.Group>
        ))}
        <Button
          style={{ marginTop: "0.25rem", marginBottom: "0.25rem" }}
          onClick={() =>
            addArrayItem(
              arrayName,
              fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
            )
          }
          color="blue"
        >
          {/* Add {label} */}
          <Icon name="add" />
          Add More
        </Button>
      </div>
    );
  };

  // ***************************************************************
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [isModalOpenFU, setModalOpenFU] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [caseSheetData, setCaseSheetData] = useState(null);

  useEffect(() => {
    // const fetchAttachment = async () => {
    //   try {
    //     const response = await fetch(
    //       `https://namaste.bhu.edu.in/pwbs/api/casesheet/${caseSheetId}/`,
    //       {
    //         headers: {
    //           Authorization: "Token 7e10b5afcfe50486b8c4a90075514dfbbb169fa7",
    //         },
    //       }
    //     );
    //     const data = await response.json();
    //     setCaseSheetData(data); // Set case sheet data
    //     if (data.attachment) {
    //       setPdfUrl(`${data.attachment}`);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching attachment:", error);
    //   }
    // };

    // if (caseSheetId) {
    //   fetchAttachment();
    // }
    const fetchAttachment = async () => {
      try {
        const data = await fetchCaseSheetData(caseSheetId);
        setCaseSheetData(data); // Set case sheet data
        if (data.attachment) {
          setPdfUrl(`${data.attachment}`);
        }
      } catch (error) {
        console.error("Error fetching attachment:", error);
      }
    };

    if (caseSheetId) {
      fetchAttachment();
    }
  }, [caseSheetId]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error("Error compressing the image:", error);
      throw error;
    }
  };

  const convertImageToPdf = async (imageFile) => {
    const pdfDoc = await PDFDocument.create();
    const imageBytes = await imageFile.arrayBuffer();
    const imageExt = imageFile.type.split("/")[1];

    let image;
    if (imageExt === "jpeg" || imageExt === "jpg") {
      image = await pdfDoc.embedJpg(imageBytes);
    } else if (imageExt === "png") {
      image = await pdfDoc.embedPng(imageBytes);
    } else {
      throw new Error("Unsupported image format");
    }

    const page = pdfDoc.addPage();
    page.drawImage(image, {
      x: 0,
      y: 0,
      width: page.getWidth(),
      height: page.getHeight(),
    });

    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: "application/pdf" });
  };
  const handleFileUpload = async () => {
    setMessage("");

    try {
      const compressedFile = await compressImage(file);

      let pdfBlob;
      if (caseSheetData && caseSheetData.attachment) {
        const response = await fetch(caseSheetData.attachment);
        const existingPdfBlob = await response.blob();

        const existingPdfDoc = await PDFDocument.load(
          await existingPdfBlob.arrayBuffer()
        );
        const newPdfDoc = await PDFDocument.create();

        const copiedPages = await newPdfDoc.copyPages(
          existingPdfDoc,
          existingPdfDoc.getPageIndices()
        );
        copiedPages.forEach((page) => newPdfDoc.addPage(page));

        const imageBytes = await compressedFile.arrayBuffer();
        const imageExt = compressedFile.type.split("/")[1];
        let image;
        if (imageExt === "jpeg" || imageExt === "jpg") {
          image = await newPdfDoc.embedJpg(imageBytes);
        } else if (imageExt === "png") {
          image = await newPdfDoc.embedPng(imageBytes);
        } else {
          throw new Error("Unsupported image format");
        }

        const page = newPdfDoc.addPage();
        page.drawImage(image, {
          x: 0,
          y: 0,
          width: page.getWidth(),
          height: page.getHeight(),
        });

        pdfBlob = new Blob([await newPdfDoc.save()], {
          type: "application/pdf",
        });
      } else {
        pdfBlob = await convertImageToPdf(compressedFile);
      }
      return pdfBlob;
    } catch (error) {
      // console.error("Error uploading file:", error);
      // setMessage("Error uploading file. Please try again.");
    } finally {
      // setUploading(false);
      // setTimeout(() => setMessage(""), 3000); // Clear the message after 3 seconds
    }
  };
  const numberOptions = Array.from({ length: 25 }, (v, k) => ({
    key: k + 1,
    text: (k + 1).toString(),
    value: k + 1,
  }));

  const openModal = () => {
    setModalOpenFU(true);
  };

  const closeModalFU = () => {
    setModalOpenFU(false);
  };

  return (
    <>
      <Navbar onLogout={logout} setModalOpen2={setModalOpen2} />
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
        <div style={{ paddingTop: "90px", paddingBottom: "90px" }}>
          {!caseSheetCloseStatus ? (
            ""
          ) : (
            <Segment>
              <Message warning>
                <Message.Header>Case Sheet Closed</Message.Header>
                <p>
                  The case sheet you were working on has been successfully
                  closed. If you require any further assistance, please contact
                  the administrator.
                </p>
              </Message>
            </Segment>
          )}
          {idInfo ? (
            ""
          ) : (
            <IdentificationForm
              caseSheetId={caseSheetId}
              counselee_id={counselee_id}
            />
          )}
          {/* <DisplayIdentificationInformation jsonData={idInfo} /> */}
          {/* <IdentificationForm caseSheetId={caseSheetId} counselee_id={counselee_id} /> */}
          <div className={passwordModalOpen ? "blur" : ""}>
            <Form autoComplete="off">
              <Segment
                style={{
                  display: "flex",
                  alignItems: "center",
                  ...segmentStyle,
                }}
                color={segmentColor}
              >
                <div style={{ width: "110px" }}>Session Number:</div>
                <Dropdown
                  placeholder=""
                  fluid
                  selection
                  options={numberOptions}
                  onChange={handleSessionChange}
                  value={sessionNumber}
                  style={{ marginLeft: "0.1rem", width: "60px" }} // Adds some space between the text and the dropdown
                />
              </Segment>

              <Segment style={segmentStyle} color={segmentColor}>
                <Grid columns={2}>
                  <Grid.Column>
                    <Header as="h3">Case Information</Header>
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <IdentificationInformationModel info={idInfo}  />
                      <Feedback
                        counsellorId={counsellor_id}
                        counseleeId={counselee_id}
                        counseleeEmail={userEmail}
                      />
                    </div>
                  </Grid.Column>
                </Grid>

                {/* -------------------------------------- */}
                {renderExistingData("Case Code", "caseCode")}
                {renderExistingData("Student Code", "studentCode")}
                {renderExistingData("Name", "name")}
                {renderExistingData("Email", "email")}
                <Form.Group widths="equal">
                  {renderExistingData(
                    "Date of Birth (DD-MM-YYYY)",
                    "dateOfBirth"
                  )}
                  {renderExistingData("Age", "age")}
                </Form.Group>
                <Form.Group inline>
                  <label>Gender</label>
                  {existingData && existingData.gender && (
                    <Message style={{ marginRight: "1rem" }}>
                      {/* <Message.Header>Existing Data:</Message.Header> */}
                      <p>{existingData.gender}</p>
                    </Message>
                  )}
                  <Form.Radio
                    label="Male"
                    name="gender"
                    value="male"
                    checked={formData.gender === "male"}
                    onChange={handleChange}
                  />
                  <Form.Radio
                    label="Female"
                    name="gender"
                    value="female"
                    checked={formData.gender === "female"}
                    onChange={handleChange}
                  />
                  <Form.Radio
                    label="Other"
                    name="gender"
                    value="other"
                    checked={formData.gender === "other"}
                    onChange={handleChange}
                  />
                </Form.Group>
                {renderExistingData(
                  "Institute/Faculty/College/School/Other",
                  "institute"
                )}
                {renderExistingData("Contact No. & Email", "contactNoEmail")}
                {renderExistingData(
                  "Emergency Contact number",
                  "emergencyContact"
                )}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                <Header as="h3">Section A - Present Status</Header>
                {renderExistingData(
                  "Major Concern in Client’s Life",
                  "majorConcern",
                  "textarea"
                )}
                <Form.Field>
                  <label>Severity of Issue</label>
                  <p style={pStyle}>Very low …1…2…3…4…5…6…7…8…9…10… Optimum</p>
                  {existingData && existingData.severity && (
                    <Message>
                      {/* <Message.Header>Existing Data:</Message.Header> */}
                      <p>{existingData.severity}</p>
                    </Message>
                  )}
                  <Input
                    type="number"
                    name="severity"
                    min="1"
                    max="10"
                    value={formData.severity}
                    onChange={handleChange}
                  />
                </Form.Field>

                {renderArrayData("Presenting Symptoms", "presentingSymptoms", [
                  { name: "symptom", label: "Symptom" },
                  { name: "duration", label: "Duration" },
                ])}
                {renderExistingData(
                  "Tentative DSM V Classification",
                  "dsmVClassification"
                )}
                {renderExistingData(
                  "Manifest Personality Traits",
                  "personalityTraits",
                  "textarea",
                  "(1) Body Status, (2) Grooming, (3) Rapport, (4) Eye Contact, (5) Attitude towards Counselor, (6) Psycho motor behavior, (7) Speech coherence, (8) Touch with Environment, (9) Health Status, (10) Energy level, (11) Emotional Control, (12) Expression, (13) Others"
                )}
                {renderExistingData("Body Status", "bodyStatus", "textarea")}
                {renderExistingData(
                  "Thinking Traits",
                  "thinkingTraits",
                  "textarea"
                )}
                {renderExistingData(
                  "Emotional Traits",
                  "emotionalTraits",
                  "textarea"
                )}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                <Header as="h3">Client History</Header>
                {/* {renderExistingData(
                  "Biological Features",
                  "biologicalFeatures",
                  "textarea"
                )} */}
                {renderExistingData(
                  "Medical History",
                  "medicalHistory",
                  "textarea"
                )}
                {renderExistingData(
                  "Psychiatric History",
                  "psychiatricHistory",
                  "textarea"
                )}
                {renderExistingData(
                  "Family Psychiatric History (If Any)",
                  "familyPsychiatricHistory",
                  "textarea"
                )}
                {renderExistingData(
                  "Counseling History",
                  "counselingHistory",
                  "textarea"
                )}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                <Header as="h3">Present Medical Status</Header>
                {/* {renderExistingData(
                  "Present Medical Status",
                  "presentMedicalStatus",
                  "textarea"
                )} */}
                {renderExistingData("Biological", "biological", "textarea")}
                {renderExistingData("Psychiatric", "psychiatric", "textarea")}
                {renderExistingData(
                  "Any medicines being presently taken",
                  "anyMedicinesBeingPresentlyTaken",
                  "textarea"
                )}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                <Header as="h3">B. Overall Social Status</Header>
                {renderExistingData(
                  "Overall Social Status",
                  "overallSocialStatus",
                  "textarea"
                )}
                {renderExistingData(
                  "Group/Peer Membership",
                  "groupPeerMembership",
                  "textarea"
                )}
                {renderExistingData(
                  "Family Relationships",
                  "familyRelationships",
                  "textarea",
                  "With 1. Father, 2. Mother, 3. Siblings, 4. other Members, 5. Between Parents, 6. Home Environment"
                )}
                {renderExistingData(
                  "Support System",
                  "supportSystem",
                  "textarea"
                )}
                {renderExistingData(
                  "Marital Status",
                  "maritalStatus",
                  "textarea"
                )}
                {renderExistingData(
                  "Participation in Extra curricular activities",
                  "extraCurricular",
                  "textarea"
                )}
                {renderExistingData(
                  "Hobbies & Interests",
                  "hobbies",
                  "textarea"
                )}
                {renderExistingData(
                  "Major Strength and Coping Potential",
                  "strengths",
                  "textarea"
                )}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                <Header as="h3">(If needed be) for Academic Counseling</Header>
                {renderArrayData("Education", "education", [
                  { name: "level", label: "Exam Level" },
                  { name: "marks", label: "Marks" },
                  {
                    name: "selfSatisfaction",
                    label: "Self Satisfaction with Marks",
                  },
                  {
                    name: "parentSatisfaction",
                    label: "Parents Satisfaction with Marks",
                  },
                ])}
                {renderExistingData(
                  "Self-Expectations and Goal",
                  "selfExpectations",
                  "textarea"
                )}
                {renderExistingData(
                  "Parental Expectations and Performance gap (if any)",
                  "parentalExpectations",
                  "textarea"
                )}
                {renderExistingData(
                  "Evaluation of School and College Life",
                  "schoolCollegeEvaluation",
                  "textarea"
                )}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                <Header as="h3">
                  Formulation of Case and Plan of Treatment
                </Header>
                {renderExistingData("Case Plan", "casePlan", "textarea")}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                <Header as="h3">Session Notes</Header>
                {/* *******************FileUpload******************* */}
                <div>
                  {true && (
                    <Form>
                      <Form.Field>
                        <label>
                          Upload Attachment (if any)(only image files)
                        </label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleFileChange}
                        />
                      </Form.Field>
                      {/* <Button
                        style={{ marginBottom: "0.5rem" }}
                        primary
                        // onClick={handleFileUpload}
                        loading={uploading}
                        disabled={uploading}
                      >
                        <Icon name="upload" /> Upload
                      </Button> */}
                      {message && <Message>{message}</Message>}
                    </Form>
                  )}
                  {pdfUrl && (
                    <>
                      <Button
                        style={{ marginBottom: "0.5rem" }}
                        onClick={openModal}
                      >
                        View Attachments
                      </Button>
                      <Modal open={isModalOpenFU} onClose={closeModalFU}>
                        <Modal.Header>Attachment</Modal.Header>
                        <Modal.Content>
                          <div style={{ height: "500px" }}>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              <Viewer fileUrl={pdfUrl} />
                            </Worker>
                          </div>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button onClick={closeModalFU}>Close</Button>
                        </Modal.Actions>
                      </Modal>
                    </>
                  )}
                </div>
                {/* <FileUpload
                  counselee_id={counselee_id}
                  caseSheetId={caseSheetId}
                  uploadSection={true}
                /> */}
                {/* {renderExistingData(
                  "Date",
                  "sessionDate",
                  "text"
                )} */}
                {/* 
                {renderArrayDataTextField("Session Notes", "sessionNotes", [
                  { name: "date", label: "Date", dataType: "date" },
                  // { name: "details", label: "Details" },
                  { name: "problem", label: "Presenting problems" },
                  { name: "interventions", label: "Interventions used" },
                  { name: "changes", label: "Changes in the client" },
                ])} */}
                {/* **************Adding Session Note Section******************** */}
                {/* sessionProblem: "",
                sessionInterventions:"",
                sessionChange:"", */}
                {/* <Segment style={{backgroundColor:"#fcf8f1"}}> */}
                {renderExistingData("Session Note", "sessionNote", "textarea")}
                {/* </Segment> */}
                {renderExistingData("Saved On", "sessionDate", "text")}
                {renderExistingData(
                  "Presenting problems",
                  "sessionProblem",
                  "textarea"
                )}
                {renderExistingData(
                  "Interventions used",
                  "sessionInterventions",
                  "textarea"
                )}
                {renderExistingData(
                  "Changes in the client",
                  "sessionChange",
                  "textarea"
                )}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                {renderExistingData(
                  "Closure notes (when issue closed)",
                  "closureNotes",
                  "textarea"
                )}
                {renderExistingData("Name of Counsellor", "counselorName")}
                {renderExistingData(
                  "Termination notes (when student leaves)",
                  "terminationNotes",
                  "textarea"
                )}
                {renderExistingData(
                  "Name & Signature of Counsellor",
                  "counselorSignature",
                  "textarea"
                )}
              </Segment>
              <Segment style={segmentStyle} color={segmentColor}>
                {renderExistingData("Extra Notes", "extra", "textarea")}
              </Segment>
              {!caseSheetCloseStatus && (
                <Button onClick={handleSave} color="blue">
                  Save
                </Button>
              )}
              {!caseSheetCloseStatus && (
                <ToggleCasesheetBtn
                  close={true}
                  counselee_id={counselee_id}
                  caseSheetId={caseSheetId}
                />
              )}
            </Form>
            {successMessage && (
              <Message
                success
                header="Success"
                content="Data saved successfully"
              />
            )}

            <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
              <Modal.Header>Enter Password to Save Data</Modal.Header>
              <Modal.Content>
                <Form autoComplete="off">
                  <Form.Field>
                    <label>Password</label>
                    <Input
                      type={passwordVisible ? "text" : "password"}
                      icon={
                        <Icon
                          name={passwordVisible ? "eye slash" : "eye"}
                          link
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        />
                      }
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Confirm Password</label>
                    <Input
                      type={confirmPasswordVisible ? "text" : "password"}
                      icon={
                        <Icon
                          name={confirmPasswordVisible ? "eye slash" : "eye"}
                          link
                          onClick={() =>
                            setConfirmPasswordVisible(!confirmPasswordVisible)
                          }
                        />
                      }
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Field>
                  <Message warning>
                    <Message.Header>
                      If you forgot the password, the data is not recoverable.
                    </Message.Header>
                  </Message>
                  <Form.Field>
                    <Checkbox
                      label="I understand that I am responsible for not sharing this password, and if this document is saved with a different password, I may be held accountable and my access to the document may be restricted."
                      checked={isCheckboxChecked}
                      onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                    />
                  </Form.Field>
                </Form>
                {successMessage && (
                  <Message
                    success
                    header="Success"
                    content="Data saved successfully"
                  />
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  disabled={!isCheckboxChecked}
                  onClick={handleModalSave}
                  primary
                >
                  Save
                </Button>
              </Modal.Actions>
            </Modal>
            {passwordCheckingModel(
              passwordModalOpen,
              handlePasswordSubmit,
              passwordVisible,
              setPasswordVisible,
              password,
              setPassword,
              passwordErrorMessage,
              handleClose
            )}
          </div>
        </div>
      </Container>
      <ScrollButton />
    </>
  );
};

export default memo(SecureForm);
