import React, { memo, useEffect, useState } from "react";
import { Icon, Card, Modal } from "semantic-ui-react";
import { convertDateTimestamp, isImageFile, linkDecorator } from "../utils";
import ReactLinkify from "react-linkify";
import { fetchHostelReportDetails } from "../api";
import LoaderComponent from "../LoaderComponent";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for leaflet's default icon issue with webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png",
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
});

function WardenMessageViewCard(props) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (props.id) {
        setLoading(true);
        try {
          const response = await fetchHostelReportDetails(props.id);
          const fetchedData = await response.json();
          // console.log(fetchedData);
          setData(fetchedData);
        } catch (error) {
          console.error("Failed to fetch srk report:", error);
        } finally {
          setLoading(false);
        }
      } else {
        console.log("item_id not available");
      }
    };
    loadData();
  }, [props.id]);

  return isLoading ? (
    <LoaderComponent loadingText={"Loading ..."} />
  ) : (
    <>
      <Card color="green" fluid>
        <Card.Content>
          <Card.Header>
            <div
              style={{
                overflowWrap: "break-word", // ensures long words are broken and wrapped to the next line
                wordWrap: "break-word", // alternative/legacy property for word wrapping
                whiteSpace: "normal", // allows text to wrap as needed
              }}
            >
              {data.title}
            </div>
            <span
              style={{
                float: "right",
                color: "black",
                fontSize: "1rem",
                // whiteSpace: "nowrap",
              }}
            >
              Date: {convertDateTimestamp(data.time_of_sending)}
            </span>
            <br />
            <span
              style={{
                float: "right",
                color: "black",
                fontSize: "1rem",
                // whiteSpace: "nowrap",
              }}
            >
              {data.hostel_name}
              {/* Report Date: {formatDate(data.report_date)} */}
            </span>
          </Card.Header>

          <Card.Description style={{ paddingTop: "0.25rem" }}>
            {/* <strong>Message:</strong>{" "} */}
            {data.body &&
              data.body.split("\n").map((line, index) => (
                <span key={index}>
                  <ReactLinkify componentDecorator={linkDecorator}>
                    {line}
                  </ReactLinkify>
                  <br />
                </span>
              ))}
          </Card.Description>

          {data.attachment && (
            <Card.Content extra style={{ paddingTop: "0.5rem" }}>
              {isImageFile(data.attachment) ? (
                <>
                  <img
                    src={data.attachment}
                    alt="Attachment"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(true)} // Open the modal
                  />
                  <Modal
                    open={open}
                    onClose={() => setOpen(false)} // Close the modal
                    size="large"
                    closeIcon
                  >
                    <Modal.Content
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Fill the entire modal height
                      }}
                    >
                      <img
                        src={data.attachment}
                        alt="Attachment"
                        style={{ maxWidth: "100%", maxHeight: "80vh" }}
                      />
                    </Modal.Content>
                  </Modal>
                </>
              ) : (
                <>
                  <Icon name="attach" />
                  <a
                    href={data.attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Attachment
                  </a>
                </>
              )}
            </Card.Content>
          )}

          {data.lat && data.lng && (
            <Card.Content extra style={{ paddingTop: "0.5rem" }}>
              <MapContainer
                center={[data.lat, data.lng]}
                zoom={16}
                style={{ height: "400px", width: "100%", zIndex: "1" }}
                scrollWheelZoom={false}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={[data.lat, data.lng]}>
                  <Popup>
                    Location: {data.lat}, {data.lng}
                  </Popup>
                </Marker>
              </MapContainer>
            </Card.Content>
          )}
        </Card.Content>
        {data.sender_name && (
          <Card.Content extra>
            <Icon name="user" />
            {data.sender_name}
          </Card.Content>
        )}
      </Card>
      {data.report_comments && data.report_comments.length > 0 && (
        <Card color="green" fluid>
          <Card.Header>
            <div style={{ padding: "1rem", fontWeight: "bold" }}>Comments</div>
          </Card.Header>
          <Card.Content>
            {data.report_comments && data.report_comments.length > 0 && (
              <Card.Content extra>
                {/* {data.report_comments
                  .sort(
                    (a, b) =>
                      new Date(b.time_of_sending) - new Date(a.time_of_sending)
                  )
                  .map((comment, index) => (
                    <div key={index} style={{ marginBottom: "1rem" }}>
                      <div>
                        <strong>Date:</strong>{" "}
                        {convertDateTimestamp(comment.time_of_sending)}
                      </div>
                      <div>
                        <strong>Comment:</strong> {comment.comment}
                      </div>
                      <div>
                        <strong>Commentator:</strong> {comment.commentator_name}
                      </div>
                    </div>
                  ))} */}

                {data.report_comments
                  .sort(
                    (a, b) =>
                      new Date(b.time_of_sending) - new Date(a.time_of_sending)
                  )
                  .map((comment, index) => (
                    <Card
                      color="blue"
                      fluid
                      key={index}
                      // style={{ marginBottom: "1rem" }}
                    >
                      <Card.Content>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <Icon name="user" /> {comment.commentator_name}
                          </div>
                          <div>
                            <strong>Date:</strong>{" "}
                            {convertDateTimestamp(comment.time_of_sending)}
                          </div>
                        </div>
                        <div style={{}}>
                          <strong>Comment:</strong> {comment.comment}
                        </div>
                      </Card.Content>
                    </Card>
                  ))}
              </Card.Content>
            )}
          </Card.Content>
        </Card>
      )}
    </>
  );
}

export default memo(WardenMessageViewCard);
