import React from "react";
import { Button, Icon, Container, Divider } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import "./QuickNavPanel.css"; // Import custom styles
import { isUserCSEOPAdmin } from "./api";

const QuickNavPanel = () => {
  const navigate = useNavigate();
  const teachOrStaff =
    localStorage.getItem("person_t") !== "STU" ? true : false;
  const personType = localStorage.getItem("person_t");
  const uwdUrl = process.env.REACT_APP_UWD_URL;
  const srkMember =
    localStorage.getItem("srk_admin_id") ||
    localStorage.getItem("srk_fellow_id")
      ? true
      : false;
  const hostelAdminMember =
    localStorage.getItem("hostel_admin_id") ||
    localStorage.getItem("hostel_warden_id")
      ? true
      : false;
  const psyCounselingMember =
    localStorage.getItem("psy_counsellor_id") ||
    localStorage.getItem("psy_admin_id")
      ? true
      : false;
  const faculty = localStorage.getItem("person_t") === "FAC" ? true : false;
  const iconSize = "large";
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <Container
      textAlign="center"
      fluid
      style={{ marginTop: "0.75rem", marginBottom: "0rem" }}
    >
      <div className="button-group">
        <div
          className="nav-button"
          onClick={() => handleNavigation("/notifications")}
        >
          <Icon name="envelope" size={iconSize} />
          <div className="button-text">Notifications</div>
        </div>

        {personType !== "STU" && (
          <div className="nav-button" onClick={() => window.open(uwdUrl)}>
            <Icon name="user" size={iconSize} />
            <div className="button-text">UWD</div>
          </div>
        )}

        {isUserCSEOPAdmin() && (
          <div
            className="nav-button"
            onClick={() => handleNavigation("/cseop/attendance")}
          >
            <Icon name="calendar alternate" size={iconSize} />
            <div className="button-text">CSEOP Attendance</div>
          </div>
        )}

        {teachOrStaff && (
          <div
            className="nav-button"
            onClick={() => handleNavigation("/directory")}
          >
            <Icon name="phone" flipped="horizontally" size={iconSize} />
            <div className="button-text">Telephone Directory</div>
          </div>
        )}

        <div
          className="nav-button"
          onClick={() => handleNavigation("/faculty/search")}
        >
          <Icon name="user" size={iconSize} />
          <div className="button-text"> Faculty Search</div>
        </div>
        <div
          className="nav-button"
          onClick={() => handleNavigation("/holidays")}
        >
          <Icon name="calendar alternate" size={iconSize} />
          <div className="button-text">Holiday Calendar</div>
        </div>
        {srkMember && (
          <div className="nav-button" onClick={() => handleNavigation("/srk")}>
            <Icon name="user" size={iconSize} />
            <div className="button-text">SRK</div>
          </div>
        )}
        {hostelAdminMember && (
          <div
            className="nav-button"
            onClick={() => handleNavigation("/wardens")}
          >
            <Icon name="home" size={iconSize} />
            <div className="button-text">Wardens</div>
          </div>
        )}
        {psyCounselingMember && (
          <div
            className="nav-button"
            onClick={() => handleNavigation("/psy/counseling")}
          >
            <Icon name="user" size={iconSize} />
            <div className="button-text">Psy. Counseling</div>
          </div>
        )}
        {faculty && (
          <div
            className="nav-button"
            onClick={() => handleNavigation("/mycourses")}
          >
            <Icon name="book" size={iconSize} />
            <div className="button-text">My Courses</div>
          </div>
        )}
        <div
          className="nav-button"
          onClick={() => handleNavigation("/central-library")}
        >
          <Icon name="book" size={iconSize} />
          <div className="button-text">Central Library</div>
        </div>
        <div
          className="nav-button"
          onClick={() => handleNavigation("/emergency-contacts")}
          style={{backgroundColor:"#ff6961"}}
        >
          <Icon name="phone" flipped="horizontally" size={iconSize} />
          <div className="button-text" style={{fontWeight:"bold"}}>Emergency Contacts</div>
        </div>

      </div>
      {/* <Divider style={{marginBottom: "0", marginTop:"0.5rem"}} /> */}
    </Container>
  );
};

export default QuickNavPanel;
