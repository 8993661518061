import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { fetchHolidays } from "../Components/api";
import "../Css/HolidayList.css";
import { Icon } from "semantic-ui-react";

const HolidayCalendar = () => {
  const [holidays, setHolidays] = useState([]);
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1);
  const endOfYear = new Date(currentYear, 11, 31);

  useEffect(() => {
    fetchHolidays()
      .then((res) => res.json())
      .then((data) => {
        setHolidays(data);
      })
      .catch((err) => {
        console.error("Error: cannot fetch holidays list: ", err);
      });
  }, []);

  const sameDay = (d1, d2) =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      let isHoliday = false;
      let isRestrictedHoliday = false;

      holidays.forEach((holiday) => {
        const fromDate = new Date(holiday.from_date);
        const tillDate = holiday.till_date ? new Date(holiday.till_date) : fromDate;
        if (sameDay(date, fromDate) || (date > fromDate && date <= tillDate)) {
          if (holiday.is_restricted) {
            isRestrictedHoliday = true;
          } else {
            isHoliday = true;
          }
        }
      });

      if (isHoliday) return "holiday";
      if (isRestrictedHoliday) return "restricted-holiday";
    }
    return null;
  };

  const tileContent = ({ date, view }) => {
    if (view === "month") {
      const holiday = holidays.find((holiday) => {
        const fromDate = new Date(holiday.from_date);
        const tillDate = holiday.till_date ? new Date(holiday.till_date) : fromDate;
        return sameDay(date, fromDate) || (date > fromDate && date <= tillDate);
      });

      if (holiday) {
        // Find a non-restricted holiday first, otherwise use the restricted one
        const holidayName = holidays
          .filter((holiday) => {
            const fromDate = new Date(holiday.from_date);
            const tillDate = holiday.till_date ? new Date(holiday.till_date) : fromDate;
            return sameDay(date, fromDate) || (date > fromDate && date <= tillDate);
          })
          .reduce((acc, holiday) => {
            return holiday.is_restricted ? acc : holiday.name_of_holiday;
          }, holiday.name_of_holiday); // Default to any holiday name

        return (
          <div className="holiday-name" style={{ fontSize: "1rem" }}>
            {holidayName}
          </div>
        );
      }
    }
    return null;
  };

  return (
    <div className="full-page-calendar">
      <Calendar
        tileClassName={tileClassName}
        tileContent={tileContent}
        className="holiday-calendar"
        minDate={startOfYear}
        maxDate={endOfYear}
      />
      <p>
        <Icon name="square" color="red" />
        <span style={{ paddingRight: "0.5rem" }}>Restricted Holiday </span>
        <Icon name="square" color="blue" />
        <span>Holiday </span>
      </p>
    </div>
  );
};

export default HolidayCalendar;
