import React, { useState } from "react";
import { Header, Menu, Segment } from "semantic-ui-react";
import "../Css/Inbox.css";
import { SrkInbox } from "../Components/SRK/SrkInbox";
import { useTitle } from "../Hooks/useTitle";
import NavbarWrapper from "../Components/Template/NavbarWrapper";

export default function Srk() {
  useTitle("Sarvepalli Radhakrishnan Internship");
  const [activeItem, setActiveItem] = useState("inbox");
  const handleItemClick = (name) => setActiveItem(name);
  const srk_fellow_id = localStorage.getItem("srk_fellow_id");
  const srk_admin_id = localStorage.getItem("srk_admin_id");

  return (
    <>
      <NavbarWrapper containerFluid={true}>
        <Header
          as={"h2"}
          style={{ fontFamily: "Times New Roman, Georgia, serif" }}
        >
          Sarvepalli Radhakrishnan Internship
        </Header>
        <Menu
          pointing
          style={srk_fellow_id ? { display: "flex" } : { display: "none" }}
        >
          <Menu.Item
            name="All Reports"
            active={activeItem === "inbox"}
            onClick={() => handleItemClick("inbox")}
          />
          {srk_fellow_id && (
            <>
              <Menu.Item
                name="sent"
                active={activeItem === "sent"}
                onClick={() => handleItemClick("sent")}
              />
              <Menu.Item
                name="create"
                active={activeItem === "create"}
                onClick={() => handleItemClick("create")}
              />
            </>
          )}
        </Menu>
        <Segment>
          {activeItem === "inbox" && <SrkInbox srk_admin_id={srk_admin_id} />}
        </Segment>
      </NavbarWrapper>
    </>
  );
}
