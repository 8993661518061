import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  Container,
  Segment,
  Header,
  Divider,
  Icon,
  Button,
} from "semantic-ui-react";
import { fetchProgramDetails } from "../Components/api";
import { formatResponseDataKey } from "../Components/utils";
import LoaderComponent from "../Components/LoaderComponent";
import { useAuth } from "../Context/AuthContext";
import Navbar from "../Components/Navbar";
import SearchModal from "../Components/Modals/SearchModal";
import NavbarWithoutLogin from "./WithoutLogin/NavbarWithoutLogin";
import DashPannelOne from "./DashPannelOne";
import { useTitle } from "../Hooks/useTitle";

export default function ProgramDetails(props) {
  const loggedIn = localStorage.getItem("isLoggedIn") === "true";
  const [programDetails, setProgramDetails] = useState();
  useTitle(programDetails?.name)
  const { state } = useLocation();
  const [isLoading, setLoading] = useState(false);
  const { programid } = useParams();
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const { logout } = useAuth();

  useEffect(() => {
    // console.log(state);
    // If state object contains program id, fetch the program details
    let programId = -1;
    if (state && state.programId) {
      programId = state.programId;
    } else if (programid) {
      programId = programid;
    }
    setLoading(true);
    fetchProgramDetails(programId)
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setProgramDetails(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Could not fetch program details: ", err);
        setLoading(false);
      });
  }, [programid, state]);

  const renderProgramDetails = () => {
    if (programDetails) {
      return (
        <Segment basic>
          {Object.keys(programDetails).map((key) => {
            if (
              key === "id" ||
              key === "courses" ||
              key === "name" ||
              key === "department_id" ||
              key === "semesters" ||
              key === "code" ||
              key === "web_url" ||
              key === "about" ||
              key === "syllabus" ||
              key === "eligibility" ||
              key === "specializations" ||
              key === "department" ||
              key === "insfac" ||
              key === "ordinance" ||
              key === "timetable" 
            )
              return null; // don't show id, name or courses data
            return (
              <p key={key}>
                <strong>{formatResponseDataKey(key)}</strong> -{" "}
                <span>
                  {programDetails[key] ? programDetails[key] : "Not Available "}
                </span>
              </p>
            );
          })}
        </Segment>
      );
    } else return <h3>Program data not found</h3>;
  };

  if (isLoading) {
    return <LoaderComponent loadingText="Loading program details..." />;
  }
  return (
    <>
      {loggedIn ? (
        <Navbar onLogout={logout} setModalOpen2={setModalOpen} />
      ) : (
        <NavbarWithoutLogin />
      )}
      <SearchModal isModalOpen={isModalOpen} closeModal={closeModal} />
      <Container>
        <div style={{ paddingTop: "90px" }}>
          <Segment basic padded>
            <DashPannelOne visibility={true} />
            <Header as="h1" className="heading-font">
              <Icon name="student" />
              <Header.Content>
                {programDetails && programDetails.name
                  ? programDetails.name
                  : "Program Dashboard"}
              </Header.Content>
            </Header>
            <Divider />
            <Link
              to="/courses/"
              state={{
                courses: programDetails ? programDetails.courses : null,
              }}
            >
              <Button className="heading-font" basic color="blue">
                View Courses
              </Button>
            </Link>
            {programDetails?.ordinance && <Button
              as="a"
              href={programDetails?.ordinance}
              target="_blank"
              rel="noopener noreferrer"
              className="heading-font"
              basic
              color="blue"
            >
              View Ordinance
            </Button>}
            {programDetails?.timetable && <Button
              as="a"
              href={programDetails?.timetable}
              target="_blank"
              rel="noopener noreferrer"
              className="heading-font"
              basic
              color="blue"
            >
              Timetable
            </Button>}
            {/* <Link to="/program/create"><Button basic color="blue">Edit Program</Button></Link> */}
            {renderProgramDetails()}
          </Segment>
        </div>
      </Container>
    </>
  );
}
